<template>
  <div class="bs-container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <Spin fix v-if="spinShow"></Spin>
      <div class="head">
        <div class="info-box brain-num">
          <div class="line left-line"></div>
          <div class="chart-container">
            <pie-chart
              :currentInfo="brainStatisticsInfo"
              :isBrain1="true"
            ></pie-chart>
            <div class="chart-num first-num">
              <div class="num">
                {{ brainStatisticsInfo.currentBrainSore }}
              </div>
              <!-- <div class="line"></div> -->
              <div class="num">
                {{ brainStatisticsInfo.totalBrainSore }}
              </div>
            </div>
          </div>
          <div class="word-box">
            <span class="title">脑力值</span>
            <span class="desc">数值是您训练情况的综合体现</span>
          </div>
        </div>
        <div class="info-box crowd-location">
          <div class="line left-line"></div>
          <div class="chart-container">
            <pie-chart
              :currentInfo="brainStatisticsInfo"
              :isBrain2="true"
            ></pie-chart>
            <div class="chart-num">
              <span class="num">{{
                brainStatisticsInfo.currTrainLocation
              }}</span>
              <span class="percent">%</span>
            </div>
          </div>
          <div class="word-box">
            <span class="title">人群排名</span>
            <span class="desc">数值是您在人群排名中的体现</span>
          </div>
        </div>
        <div class="info-box week-up">
          <div class="line left-line"></div>
          <div class="chart-container">
            <pie-chart
              :currentInfo="brainStatisticsInfo"
              :isBrain3="true"
            ></pie-chart>
            <div class="chart-num">
              <span class="num">{{
                brainStatisticsInfo.currWeekIncrease
              }}</span>
              <span class="percent">%</span>
            </div>
          </div>
          <div class="word-box">
            <div class="title-icon">
              <span class="title">周提升</span>
              <div
                class="icon icon-up"
                v-if="brainStatisticsInfo.brainScoreWeekUpFlg === 1"
              ></div>
              <div
                class="icon icon-down"
                v-if="brainStatisticsInfo.brainScoreWeekUpFlg === 0"
              ></div>
            </div>
            <span class="desc">数值是您周提升的体现</span>
          </div>
          <div class="line right-line"></div>
        </div>
        <!-- <div class="stage-report" @click="gotoStageReport">
          <img src="@/assets/img/patientend/wenhao.png" alt="" class="wenhao" />
          <span>阶段报告</span>
          <div class="icon"></div>
        </div> -->
      </div>
      <div class="bt">
        <div class="chart-left">
          <div class="title">实时脑力指数图</div>
          <div class="chart-box">
            <line-chart
              :chartInfo="brainStatisticsInfo"
              v-if="isBrainScoreChartShow"
            ></line-chart>
          </div>
          <!-- 图表没有数据的时候展示文字 -->
          <div class="chartNoData" v-if="!isBrainScoreChartShow">暂无数据</div>
        </div>
        <div class="chart-right">
          <div class="title">
            训练时长(共计{{ brainStatisticsInfo.totalTrainTime }}分钟)
          </div>
          <div class="chart-box">
            <bar-chart
              :chartInfo="brainStatisticsInfo"
              v-if="isBrainScoreChartShow"
            ></bar-chart>
          </div>
          <!-- 图表没有数据的时候展示文字 -->
          <div class="chartNoData" v-if="!isBrainScoreChartShow">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
import lineChart from "@v/patientend/chartItems/brainStatistics/lineChart1";
import barChart from "@v/patientend/chartItems/brainStatistics/barChart1";
import pieChart from "@v/patientend/chartItems/brainStatistics/pieChart";
// import radar from "./items/radarCharts"; // 雷达图
// import radar from "./items/lineCharts"; // 折线结果图
export default {
  data() {
    return {
      modal9: false,
      spinShow: true,
      currentPercent: 70,
      brainStatisticsInfo: "",
      isBrainScoreChartShow: true,
      isTrainTimeChartShow: true,
    };
  },
  components: {
    // radar,
    // tabarhead,
    lineChart,
    barChart,
    pieChart,
  },

  created() {
    this.patientId = this.$store.state.patientInfo.patientId;
    //    this.schedId = this.$routes.query.schedId
    this.getInitData();
    console.log(this.$store.state.patientInfo);
  },

  computed: {},

  mounted() {
    setTimeout(() => {
      this.spinShow = false;
    }, 100);
    this.$Message.config({
      top: 280,
    });
  },

  methods: {
    gotoStageReport(e) {
      console.log("gotoStageReport", e);
      if (e.target.className === "wenhao") {
        // if (this.timer) {
        //     clearTimeout(this.timer)
        // }
        // this.modal9 = true
        // this.timer = setTimeout(() => {
        //     this.$Modal.remove()
        // }, 2000);
        // this.$Modal.info(
        //     {
        //         width: '700px',
        //         render: (h, params) => {
        //             return h('div', {
        //                 style: {
        //                     fontSize: '20px'
        //                 }
        //             }, '相同方案训练间隔不超7日，且达到7次，即可获得阶段报告。')
        //         }
        //     }
        // )
        this.$Message.info(
          `相同方案训练间隔不超7日，且达到7次，即可获得阶段报告。`
        );
      } else {
        this.$router.push("/main/stagereport");
      }
    },
    async getInitData() {
      const data = {
        patientId: this.patientId,
        // schedId: this.schedId // todo
        // schedId: 'bf8ed196e0ab4a3c9b059bd58769483c'
      };
      try {
        const res = await this.$http.gets(
          "patient/plan/info/getBrainScoreInfo",
          this.$http.adornParams(data, false)
        );
        if (res.code === 1) {
          this.brainStatisticsInfo = res.data;
          this.isBrainScoreChartShow =
            this.brainStatisticsInfo.brainScoreDayList.length > 0;
          this.isTrainTimeChartShow =
            this.brainStatisticsInfo.trainTimeDayList.length > 0;
          console.log("brainStatisticsInfo", res.data);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bs-container {
  position: relative;
  display: flex;
  justify-content: center;
  background: #fafafa;
  height: 100% !important;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    // height: 794px;
    height: 770px; // 为了显示底部产品信息
    // height: 900px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    line-height: 1;
    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 108px;
      padding: 60px;
      border-radius: 40px 40px 0 0;
      border-bottom: 1px solid #d2d2d2;
      // background: lightslategrey;
      .line {
        width: 1px;
        height: 18px;
        margin-right: 60px;
        background: #d2d2d2;
      }
      .info-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        // background: pink;
        .chart-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 68px;
          height: 68px;
          margin-right: 11px;
          border-radius: 50%;
          // background: #ccc;
          .chart-num {
            position: absolute;
            .num {
              font-size: 20px;
              font-weight: 500;
              color: #000;
            }
            .percent {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
          }
          .first-num {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .num:nth-child(2) {
              border-top: 1px solid #000;
            }
            // .line {
            //     width: 60px;
            //     height: 1px;
            //     color: #000;
            // }
          }
        }
        .word-box {
          display: flex;
          flex-direction: column;
          .title {
            margin-bottom: 8px;
            font-size: 24px;
            font-weight: 500;
            color: #1881e6;
          }
          .desc {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
        .left-line {
          margin-left: 98px;
        }
        .right-line {
          margin-left: 98px;
          margin-right: 78px;
        }
      }
      .stage-report {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 175px;
        height: 48px;
        // margin-left: 78px;
        background: #e7f2fd;
        border-radius: 24px;
        cursor: pointer;
        span {
          margin-right: 9px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #4174f7;
          line-height: 28px;
        }
        .icon {
          width: 13px;
          height: 15px;
          background: url("~@/assets/img/patientend/go-stage-report.png");
          background-size: cover;
        }
        .wenhao {
          width: 18px;
          margin-right: 10px;
        }
      }
      .brain-num {
        .left-line {
          margin-left: 0;
        }
      }
      // 上下箭头 为week-up单独设置样式
      .week-up {
        .title-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;
          .title {
            margin-bottom: unset;
          }
          .icon-up {
            background: url("~@/assets/img/patientend/brain-up.png");
          }
          .icon-down {
            background: url("~@/assets/img/patientend/brain-down.png");
          }
          .icon {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            background-size: cover;
          }
        }
      }
    }
    .bt {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 40px;
      .chart-left {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 710px;
        height: 480px;
        margin-right: 20px;
        border: 1px solid #ccc;
        .chart-box {
        }
        .title {
          position: absolute;
          top: 15px;
          left: 25px;
          font-size: 22px;
          font-weight: 500;
          color: #000;
        }
        // background: #ccc;
      }
      .chart-right {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 710px;
        height: 480px;
        border: 1px solid #ccc;
        // background: #ccc;
        .title {
          position: absolute;
          top: 15px;
          left: 25px;
          font-size: 22px;
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
}
.chartNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  z-index: 999;
  color: #ccc;
}
</style>
