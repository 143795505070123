<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <Spin fix v-if="spinShow"></Spin>
      <div class="head">
        <div class="title">训练报告详情</div>
        <div class="close-btn" @click="backToHome"></div>
      </div>
      <div class="middle">
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>名称</td>
              <td>训练日期</td>
              <td>本次得分</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in scaleReport" :key="index">
              <td>{{ pageInfo.pageNumber * pageInfo.pageSize + index + 1 }}</td>
              <td>{{ item.trainName }}</td>
              <td>{{ item.createTime }}</td>
              <td>{{ item.trainScore }}</td>
            </tr>
          </tbody>
        </table>
        <div class="page">
          <Page
            :total="totalCount"
            :current="pageInfo.pageNumber + 1"
            :page-size="pageInfo.pageSize"
            show-total
            @on-change="changPageNum"
            @on-page-size-change="changPageSize"
          />
        </div>
        <!-- <div class="modal-desc">
                    <h1>测评结果说明：</h1>
                    <div
                        v-html="item.content"
                        v-for="(item, index) in scaleReport.resultContents"
                        :key="index"
                    ></div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
export default {
  data() {
    return {
      spinShow: true,
      scaleReport: "",
      pageInfo: {
        pageNumber: 0,
        pageSize: 5,
      },
      totalCount: 0,
    };
  },

  components: {
    // tabarhead,
  },

  computed: {
    // content() {
    //     if (this.scaleReport) {
    //         return this.scaleReport.content;
    //     }
    //     return ''
    // },
  },
  mounted() {
    this.trainLogId = this.$route.query.trainLogId;
    // this.trainLogId = '0024e2123af8ed6716f3027f47fd9e8b';
    this.getInitData();
    setTimeout(() => {
      this.spinShow = false;
    }, 100);
  },

  methods: {
    getInitData() {
      // 获取报告详情
      let data1 = {
        trainLogId: this.trainLogId,
        pageNumber: this.pageInfo.pageNumber,
        pageSize: this.pageInfo.pageSize,
      };
      this.$http
        .post(
          "/patient/log/queryPatientTrainLoginfo",
          this.$http.adornData(data1, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log("res", res);
          if (res.code === 1) {
            // todo
            this.scaleReport = res.data;
            this.totalCount = res.count;
            console.log("this.PlanTrainLogs", this.scaleReport);
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backToHome() {
      this.$router.push("/main/patientrecord");
    },
    // 分页改变页数
    changPageNum(num) {
      this.pageInfo.pageNumber = num - 1;
      this.getInitData();
    },
    // 分页改变条数
    changPageSize(size) {
      this.pageInfo.pageSize = size;
      this.getInitData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@css/mixin.scss";
.container {
  position: relative;
  height: 100%;
  background-color: #fafafa;
  .card {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    // height: 794px;
    height: 770px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 14px;
    line-height: 1;
    .ivu-spin {
      border-radius: 40px 40px 0 0;
    }
    .head {
      position: relative;
      height: 77px;
      padding-top: 26px;
      margin-bottom: 60px;
      border-radius: 40px 40px 0 0;
      &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -3px);
        content: "";
        width: 48px;
        height: 3px;
        margin-bottom: 60px;
        background: #4174f7;
        border-radius: 2px;
      }
      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #4174f7;
      }
      .close-btn {
        position: absolute;
        top: 35px;
        right: 53px;
        width: 40px;
        height: 40px;
        background: url("~@/assets/img/patientend/i-close.png") no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
      }
    }
    .middle {
      padding: 0 211px;
      table {
        width: 100%;
        margin-bottom: 40px;
        tr {
          td {
            height: 70px;
            border: 1px solid rgba(199, 199, 199, 1);
            padding: 20px;
            text-align: center;
            font-size: 22px;
            font-weight: 400;
            color: #5f5f5f;
          }
        }
        thead {
          tr {
            background: #e5f2fc;
            td {
              height: 70px;
              font-size: 20px;
              font-weight: 400;
              color: #5f5f5f;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 20px;
              font-weight: 300;
              color: #606060;
              .reds {
                // color: red;
                color: "";
              }
            }
          }
        }
      }
      .modal-desc {
        padding: 18px 0 30px 0;
        // border-bottom: 1px solid rgba(222, 222, 222, 1);
        h1 {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 400;
          color: #5f5f5f;
        }
        div {
          font-size: 20px;
          font-weight: 400;
          color: #5f5f5f;
          opacity: 0.7;
        }
      }
    }
    .page {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
