<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <div class="head">
        <div class="title">
          <div class="icon"></div>
          <span>您的今日训练已完成！</span>
        </div>
        <div class="close-btn" @click="backToHome"></div>
      </div>
      <div class="bt">
        <div
          class="seven-notice"
          v-if="brainStatisticsInfo.sevenDaysReportFlag === 1"
        >
          <span>您已完成7天的训练，快去看看您的训练结果吧</span>
          <div class="seven-btn" @click="$router.push('/main/brainstatistics')">
            查看脑力指数
          </div>
        </div>
        <div class="num-box">
          <div class="brain-box">
            <div class="icon"></div>
            <span class="name">今日脑力指数:</span>
            <span class="val">{{ chartDataList.brainScore }}</span>
          </div>
          <div class="percent-box">
            <div class="word">超过</div>
            <div class="percent">{{ chartDataList.radarPercent }}%</div>
            <div class="word">的训练用户</div>
          </div>
        </div>
        <div class="chart-box">
          <div class="left">
            <div class="hd">
              <div class="word-box">
                <span class="word">您有以下</span>
                <span class="num">{{ chartDataList.trainTypeUpCount }}</span>
                <span class="word">个认知领域获得了提高</span>
              </div>
            </div>
            <div class="bt">
              <div class="chart-wrapper">
                <radar-chart :radarInfo="chartDataList"></radar-chart>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="hd">
              <div class="word-box">
                <span class="word">实时脑力指数</span>
              </div>
            </div>
            <div class="bt">
              <div class="chart-wrapper">
                <line-chart :chartInfo="brainStatisticsInfo"></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="placeholder"></div>sevenDaysReportFlag
            <div class="slogan">您的今日训练已完成！今日脑力指数：100</div>
            <div class="slogan">超过{{chartDataList.radarPercent}}%的测评用户</div> -->
      <!-- <line-location></line-location> -->
    </div>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
import radarChart from "@v/patientend/chartItems/todayStatistics/radarCharts";
import lineChart from "@v/patientend/chartItems/brainStatistics/lineChart1";
export default {
  data() {
    return {
      chartDataList: "",
      brainStatisticsInfo: "",
    };
  },

  components: {
    // tabarhead,
    radarChart,
    lineChart,
  },

  computed: {},

  created() {
    this.patientId = this.$store.state.patientInfo.patientId;
    this.schedId = this.$route.query.schedId; // TODO
    this.getInitData();
    this.getBrainStatistics();
    console.log(this.$store.state.patientInfo);
  },

  methods: {
    async getBrainStatistics() {
      const data = {
        patientId: this.patientId,
      };
      try {
        const res = await this.$http.gets(
          "patient/plan/info/getBrainScoreInfo",
          this.$http.adornParams(data, false)
        );
        if (res.code === 1) {
          this.brainStatisticsInfo = res.data;
          console.log("brainStatisticsInfo", res.data);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    backToHome() {
      this.$router.push("/main");
    },
    async getInitData() {
      const data = {
        patientId: this.patientId,
        // patientId: "3629a7f15ade4887aeb0aeb1404c5137",
        schedId: this.schedId, // TODO
        // schedId: "de60679179f0457a9fb5cd59aa37b260"
      };
      try {
        const res = await this.$http.gets(
          "train/data/getTrainRadar",
          this.$http.adornParams(data, false)
        );
        if (res.code === 1) {
          this.chartDataList = res.data;
          console.log("rader data", res);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  justify-content: center;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    // height: 794px;
    height: 900px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    line-height: 1;
    .head {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // align-items: center;
      position: relative;
      height: 108px;
      width: 100%;
      padding-left: 60px;
      background: #fff;
      border-radius: 40px 40px 0 0;
      border-bottom: 1px solid #d2d2d2;
      line-height: unset;
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 36px;
        // line-height: 1px;
        .icon {
          width: 34px;
          height: 36px;
          margin-right: 10px;
          background: url("~@/assets/img/patientend/brain-smile.png") no-repeat;
          background-size: cover;
        }
        span {
          font-size: 28px;
          font-weight: 500;
          color: #000000;
        }
      }
      // head中右上角的关闭按钮
      .close-btn {
        position: absolute;
        top: 30px;
        right: 53px;
        width: 40px;
        height: 40px;
        background: url("~@/assets/img/patientend/i-close.png") no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
      }
    }
    .bt {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 20px;
      .seven-notice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 666px;
        height: 48px;
        margin-bottom: 20px;
        background: #eefbee;
        border: 1px solid #08b300;
        border-radius: 24px;
        span {
          margin-left: 29px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
        .seven-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 128px;
          height: 32px;
          margin-right: 8px;
          background: #08b300;
          border-radius: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .num-box {
        display: flex;
        flex-direction: row;
        height: 25px;
        // margin-top: 20px;
        .brain-box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
          padding-right: 30px;
          border-right: 1px solid #d2d2d2;
          .icon {
            width: 28px;
            height: 24px;
            background: url("~@/assets/img/patientend/brain-score.png")
              no-repeat;
            background-size: contain;
          }
          .name {
            margin-left: 14px;
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          .val {
            margin-left: 14px;
            font-size: 28px;
            font-weight: 500;
            line-height: 20px !important;
            color: #000000;
          }
        }
        .percent-box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
          padding-left: 30px;
          .word {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          .percent {
            margin: 0 14px;
            font-size: 28px;
            font-weight: 500;
            line-height: 20px !important;
            color: #000000;
          }
        }
      }
      .chart-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 40px;
        .left {
          width: 710px;
          // height: 450px;
          height: 500px;
          margin-right: 20px;
          padding-top: 20px;
          border: 1px solid #d2d2d2;
          border-radius: 5px;
          // background: #ccc;
          .hd {
            height: 62px;
            width: 100%;
            padding: 0 60px 0;
            .word-box {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 100%;
              border-bottom: 1px solid #5f5f5f;
            }
            .word {
              font-size: 24px;
              font-weight: 500;
              color: #000000;
            }
            .num {
              margin: 0 8px;
              font-size: 28px;
              font-weight: 500;
              color: #4174f7;
            }
          }
          .bt {
            position: relative;
            height: 417px;
            // height: 500px;
            padding-top: unset;
            .chart-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              width: 100%;
              height: 100%;
              #radar-container {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .right {
          width: 710px;
          // height: 450px;
          height: 500px;
          // margin-right: 20px;
          padding-top: 20px;
          border: 1px solid #d2d2d2;
          border-radius: 5px;
          // background: #ccc;
          .hd {
            height: 62px;
            width: 100%;
            padding: 0 60px 0;
            .word-box {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 100%;
              border-bottom: 1px solid #5f5f5f;
            }
            .word {
              font-size: 24px;
              font-weight: 500;
              color: #000000;
            }
          }
          .bt {
            position: relative;
            height: 417px;
            // height: 500px;
            padding-top: unset;
            .chart-wrapper {
              position: absolute;
              top: -10px;
              left: -10px;
              width: 100%;
              height: 100%;
              /deep/ #lineChart1-container {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .placeholder {
      height: 100px;
    }
  }
}
</style>
