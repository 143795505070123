<template>
  <div class="model_content">
    <div class="model_top">
      <div class="model_top_item">
        <div class="model_top_item_label">姓名：</div>
        <div class="model_top_item_value">{{ scaleReport.name }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">性别：</div>
        <div class="model_top_item_value">{{ scaleReport.gender }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">年龄：</div>
        <div class="model_top_item_value">{{ scaleReport.age }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">教育年限：</div>
        <div class="model_top_item_value">{{ scaleReport.eduTotal }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">编号：</div>
        <div class="model_top_item_value">{{ scaleReport.reportNo }}</div>
      </div>
    </div>
    <div
      class="model_bottom"
      v-for="(item, index) in scaleReport.resultAdviceList"
      :key="index"
    >
      <div class="modal-desc">
        <h1>{{ item.title }}</h1>
      </div>
      <div class="model_buttom_item">
        <div class="model_buttom_item_label">{{ item.resultName }}：</div>
        <p class="model_bottom_item_assessmentResult">
          {{ item.resultContent }}
        </p>
      </div>
      <div class="model_buttom_item">
        <div class="model_buttom_item_label">{{ item.adviceName }}：</div>
        <p class="model_bottom_item_assessmentResult">
          {{ item.adviceContent }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reportRourthDetails",
  props: ["scaleReport"],
  mounted() {
    console.log(this.scaleReport);
  },
};
</script>

<style lang="scss" scoped>
.model_content {
  padding: 10px;
  width: 60%;
  margin: 0 auto;
}
.model_content {
  padding: 20px;
}
.model_top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;

  .model_top_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .model_top_item_label {
    color: #999999;
    font-size: 18px;
  }

  .model_top_item_value {
    color: #333333;
    font-size: 18px;
  }
}
.model_bottom {
  border: 1px solid #ccc;
  padding: 0 2%;
  border-radius: 15px;
  margin: 2% 0;
}

.modal-desc {
  padding: 18px 0 15px 0;
  border-bottom: 1px solid rgba(222, 222, 222, 1);

  h1 {
    font-size: 20px;
    font-weight: bolder;
    color: rgb(94, 134, 243);
  }

  div {
    font-size: 20px;
    font-weight: 500;
    color: rgba(52, 52, 52, 1);
  }
}
.model_buttom_item {
  margin: 2% 0;
}
.model_buttom_item_label {
  font-weight: 500;
  font-size: 20px;
}
.model_bottom_item_assessmentResult {
  margin: 2% 0;
  text-indent: 2em;
  font-size: 16px;
  line-height: 25px;
}
</style>
