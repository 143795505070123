<template>
  <div id="lineChart1-container"></div>
</template>

<script>
// import echarts from 'echarts'
export default {
  data() {
    return {
      options: {
        // title: {
        //     text: '实时脑力指数图',
        //     textStyle: {
        //         fontSize: 16,
        //         fontWeight: 500,
        //     }
        // },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 80,
          left: 60,
          right: 60,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            rotate: -40,
          },
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#aaa",
              // width: 8, // 这里是为了突出显示加上的
            },
          },
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#ccc",
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
              // width: 8, // 这里是为了突出显示加上的
            },
          },
        },
        series: [
          {
            // data: [820, 932, 901, 934, 1290, 1330, 1320],
            data: [],
            type: "line",
            itemStyle: {
              normal: {
                color: "#4174F7",
                lineStyle: {
                  color: "#4174F7",
                },
              },
            },
          },
        ],
      },
      myChart: "",
    };
  },

  props: ["chartInfo"],

  components: {},

  computed: {},

  watch: {
    chartInfo() {
      if (this.chartInfo) {
        this.options.xAxis.data = this.chartInfo.brainScoreDayList;
        this.options.series[0].data = this.chartInfo.brainScoreList;
        this.buildChart();
      }
    },
  },

  async mounted() {
    this.myChart = this.$echarts.init(
      document.getElementById("lineChart1-container")
    );
    this.myChart.setOption(this.options);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },

  methods: {
    buildChart() {
      this.myChart.setOption(this.options);
    },
  },
};
</script>
<style lang='css' scoped>
#lineChart1-container {
  width: 700px;
  height: 500px;
}
</style>