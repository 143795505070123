<template>
  <div class="model_content">
    <div class="model_top">
      <div class="model_top_item">
        <div class="model_top_item_label">姓名：</div>
        <div class="model_top_item_value">{{ scaleReport.name }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">性别：</div>
        <div class="model_top_item_value">{{ scaleReport.gender }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">年龄：</div>
        <div class="model_top_item_value">{{ scaleReport.age }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">教育年限：</div>
        <div class="model_top_item_value">{{ scaleReport.eduTotal }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">编号：</div>
        <div class="model_top_item_value">{{ scaleReport.reportNo }}</div>
      </div>
    </div>
    <div class="model_top_bottom">
      <p>{{ scaleReport.navigationLanguage }}</p>
    </div>
    <div class="model_table physical">
      <div class="model_table_title">测评结果：</div>
      <table>
        <thead>
          <tr>
            <td>序号</td>
            <td>项目</td>
            <td>内容</td>
            <td>实际得分</td>
            <td>标准分数</td>
            <td>结果</td>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(item, index) in scaleReport.detailContents">
            <td>{{ index + 1 }}</td>
            <td>{{ item.projectName }}</td>
            <td>{{ item.content }}</td>
            <td>{{ item.score }}</td>
            <td>{{ item.standardScore }}</td>
            <td :class="item.result === '一般' ? '' : ''">
              {{ item.result }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="model_table_bottom">时间：{{ scaleReport.reportDate }}</div>
    </div>
    <div
      :key="index"
      class="model_explain"
      v-for="(item, index) in scaleReport.resultContents"
    >
      <div class="model_table_title">{{ item.title }}</div>
      <div class="model_text">
        <p>{{ item.content }}</p>
      </div>
      <div
        :key="resultIndex"
        class="model_explain_content"
        v-for="(resultDto, resultIndex) in item.resultDtoList"
      >
        <div class="model_explain_content_top model_explain_content_top_bg">
          <div class="model_explain_content_top_point"></div>
          <div class="model_explain_content_top_title">
            {{ resultDto.aioTitle }}
          </div>
        </div>
        <div class="model_explain_content_text">
          <div class="model_text">
            <p>{{ resultDto.aioContent }}</p>
            <div class="model_text_img">
              <img
                alt=""
                src="@/assets/img/report/naotu_1.png"
                v-if="resultDto.aioIndex === '1'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_2.png"
                v-if="resultDto.aioIndex === '2'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_3.png"
                v-if="resultDto.aioIndex === '3'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_4.png"
                v-if="resultDto.aioIndex === '4'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_5.png"
                v-if="resultDto.aioIndex === '5'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_6.png"
                v-if="resultDto.aioIndex === '6'"
              />
              <img
                alt=""
                src="@/assets/img/report/naotu_7.png"
                v-if="resultDto.aioIndex === '7'"
              />
            </div>
            <p>{{ resultDto.aioContentDown }}</p>
          </div>
          <div class="model_explain_content_textImage">
            <div class="textImage_title">{{ resultDto.aioScore }}</div>
            <img
              alt=""
              src="@/assets/img/report/xiajiang.png"
              v-if="resultDto.imgType === '1'"
            />
            <img
              alt=""
              src="@/assets/img/report/zhengchang.png"
              v-if="resultDto.imgType === '2'"
            />
            <img
              alt=""
              src="@/assets/img/report/jiaohao.png"
              v-if="resultDto.imgType === '3'"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      :key="adviceIndex"
      class="model_advise"
      v-for="(advice, adviceIndex) in scaleReport.adviceContents"
    >
      <div class="model_table_title">{{ advice.title }}</div>
      <div class="model_text">
        <p>{{ advice.content }}</p>
      </div>
      <div
        :key="resultDtoIndex"
        v-for="(resultDto, resultDtoIndex) in advice.resultDtoList"
      >
        <div class="model_explain_content_top">
          <div class="model_explain_content_top_point"></div>
          <div class="model_explain_content_top_title">
            {{ resultDto.aioTitle }}
          </div>
        </div>
        <div class="model_text">
          <p>{{ resultDto.aioContent }}</p>
        </div>
      </div>
      <!-- <div class="model_bottom">
        <div class="model_bottom_text">{{ scaleReport.noticeText }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "reportFunctionDetails",
  props: ["scaleReport"],
};
</script>

<style lang="scss" scoped>
.model_content {
  padding: 10px;
  width: 60%;
  margin: 0 auto;
}
.model_content {
  padding: 20px;
}
.model_top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;

  .model_top_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .model_top_item_label {
    color: #999999;
    font-size: 18px;
  }

  .model_top_item_value {
    color: #333333;
    font-size: 18px;
  }
}

.model_top_bottom {
  text-indent: 30px;
  p {
    color: #333333;
    font-weight: 500;
    line-height: 180%;
    font-size: 20px;
  }
}

.model_table_title {
  font-weight: 400;
  color: #4174f7;
  line-height: 36px;
}

.model_table_bottom {
  margin-top: 10px;
  float: right;
}

.model_explain_content {
  border: 1px solid rgba(199, 199, 199, 1);
  padding-bottom: 20px;
  margin: 20px 0px;
}

.model_explain_content_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .model_explain_content_top_point {
    width: 10px;
    height: 10px;
    background: #4174f7;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 8px;
  }

  .model_explain_content_top_title {
    font-weight: 600;
    color: #333333;
    line-height: 40px;
    font-size: 20px;
  }
}

.model_explain_content_top_bg {
  background: #edf6ff;
}

.model_explain_content_text {
  margin: 10px;

  .model_explain_content_textImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .textImage_title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
    }

    img {
      width: 50%;
    }
  }
}

.model_table {
  margin-bottom: 20px;
}

/deep/ .physical {
  table {
    width: 100%;
    border-top: 1px solid rgba(199, 199, 199, 1);
    border-bottom: 1px solid rgba(199, 199, 199, 1);

    tr {
      td {
        padding: 8px;
        text-align: center;
      }
    }

    thead {
      tr {
        td {
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          background: rgba(15, 134, 232, 0.11);
          border-bottom: 1px solid rgba(199, 199, 199, 1);
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
        }
        .reds {
          // color: #ff7e00 !important;
        }
      }
    }
  }
}

.model_text {
  text-indent: 30px;

  p {
    color: #333333;
    font-weight: 400;
    line-height: 180%;
    font-size: 18px;
  }

  .model_text_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
    }
  }
}

.model_bottom {
  border-top: 1px solid rgba(199, 199, 199, 1);
  margin: 20px;

  .model_bottom_text {
    margin: 10px;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
  }
}
</style>
