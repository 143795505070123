<template>
  <div class="model_content">
    <div class="model_top">
      <div class="model_top_item">
        <div class="model_top_item_label">姓名：</div>
        <div class="model_top_item_value">{{ scaleReport.name }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">性别：</div>
        <div class="model_top_item_value">{{ scaleReport.gender }}</div>
      </div>
      <div class="model_top_item">
        <div class="model_top_item_label">年龄：</div>
        <div class="model_top_item_value">{{ scaleReport.age }}</div>
      </div>
    </div>
    <div class="model_top_bottom">
      <p>{{ scaleReport.desc }}</p>
    </div>
    <div class="model_explain">
      <div class="model_explain_content">
        <div class="model_explain_content_top model_explain_content_top_bg">
          <div class="model_explain_content_top_title">记忆力：</div>
        </div>
        <div class="model_explain_content_text">
          <div class="model_text">
            <p>{{ scaleReport.firstDesc }}</p>
          </div>
          <div class="model_explain_content_textImage">
            <div class="textImage_title">
              评估结果：{{ scaleReport.firstScore }}
            </div>
            <img
              alt=""
              src="@/assets/img/report/xiajiang.png"
              v-if="scaleReport.firstScoreFlag === 1"
            />
            <img
              alt=""
              src="@/assets/img/report/zhengchang.png"
              v-if="scaleReport.firstScoreFlag === 2"
            />
            <img
              alt=""
              src="@/assets/img/report/jiaohao.png"
              v-if="scaleReport.firstScoreFlag === 3"
            />
          </div>
        </div>
      </div>
      <div class="model_explain_content">
        <div class="model_explain_content_top model_explain_content_top_bg">
          <div class="model_explain_content_top_title">执行功能：</div>
        </div>
        <div class="model_explain_content_text">
          <div class="model_text">
            <p>{{ scaleReport.secondDesc }}</p>
          </div>
          <div class="model_explain_content_textImage">
            <div class="textImage_title">
              评估结果：{{ scaleReport.secondScore }}
            </div>
            <img
              alt=""
              src="@/assets/img/report/xiajiang.png"
              v-if="scaleReport.secondScoreFlag === 1"
            />
            <img
              alt=""
              src="@/assets/img/report/zhengchang.png"
              v-if="scaleReport.secondScoreFlag === 2"
            />
            <img
              alt=""
              src="@/assets/img/report/jiaohao.png"
              v-if="scaleReport.secondScoreFlag === 3"
            />
          </div>
        </div>
      </div>
      <div class="model_explain_content">
        <div class="model_explain_content_top model_explain_content_top_bg">
          <div class="model_explain_content_top_title">加工速度：</div>
        </div>
        <div class="model_explain_content_text">
          <div class="model_text">
            <p>{{ scaleReport.thirdDesc }}</p>
          </div>
          <div class="model_explain_content_textImage">
            <div class="textImage_title">
              评估结果：{{ scaleReport.thirdScore }}
            </div>
            <img
              alt=""
              src="@/assets/img/report/xiajiang.png"
              v-if="scaleReport.thirdScoreFlag === 1"
            />
            <img
              alt=""
              src="@/assets/img/report/zhengchang.png"
              v-if="scaleReport.thirdScoreFlag === 2"
            />
            <img
              alt=""
              src="@/assets/img/report/jiaohao.png"
              v-if="scaleReport.thirdScoreFlag === 3"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="model_bottom">
      <div class="model_top_bottom">
        <p>{{ scaleReport.resultText }}</p>
      </div>
      <div class="model_top_bottom">
        <p>{{ scaleReport.adviceText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["scaleReport"],
  name: "seniorReport",
};
</script>

<style lang="scss" scoped>
.model_content {
  padding: 10px;
  width: 50%;
  margin: 0 auto;
}
.model_top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;

  .model_top_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .model_top_item_label {
    color: #999999;
    font-size: 20px;
  }
  .model_top_item_value {
    color: #333333;
    font-size: 20px;
  }
}

.model_top_bottom {
  text-indent: 30px;
  margin: 20px auto;
  p {
    color: #333333;
    font-weight: 500;
    line-height: 180%;
    font-size: 20px;
  }
}

.model_table_title {
  font-weight: 400;
  color: #4174f7;
  line-height: 36px;
}

.model_table_bottom {
  margin-top: 10px;
  float: right;
}

.model_explain_content {
  border: 1px solid rgba(199, 199, 199, 1);
  padding-bottom: 20px;
  margin: 20px 0px;
}

.model_explain_content_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .model_explain_content_top_title {
    font-weight: 600;
    color: #333333;
    line-height: 50px;
    font-size: 24px;
    margin-left: 10px;
  }
}

.model_explain_content_top_bg {
  background: #edf6ff;
}

.model_explain_content_text {
  margin: 10px;

  .model_explain_content_textImage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    .textImage_title {
      font-size: 20px;
      font-weight: 500;
      color: #2b2b2b;
      line-height: 50px;
      margin-right: 10px;
    }
    img {
      width: 50%;
    }
  }
}
/deep/ .physical {
  .ivu-modal {
    width: 1000px !important;
    height: 560px;
  }
}
.model_text {
  text-indent: 30px;
  margin: 20px 10px;
  p {
    color: #757575;
    font-weight: 400;
    line-height: 180%;
    font-size: 20px;
  }
  .model_text_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 40%;
    }
  }
}
.model_bottom {
  margin: 20px;
}
</style>
