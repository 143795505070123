<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <div class="hd">
        <h1>测评明细</h1>
        <!-- <Form :model="searchItem" ref="searchItems" :label-width="100"> -->
        <!-- <Row :gutter="50"> -->
        <!-- <i-col span="6"> -->
        <!-- <div class="formitem-box">
            <FormItem label="测评名称：" prop="groupId">
              <Select
                v-model="searchItem.groupId"
                size="large"
                style="width: 150px"
              >
                <Option
                  :value="item.groupId"
                  v-for="(item, index) in groupList"
                  :key="index"
                  >{{ item.assessName }}
                </Option>
              </Select>
            </FormItem>
          </div> -->
        <!-- </i-col> -->
        <!-- <i-col span="12"> -->
        <!-- <div class="formitem-box">
            <FormItem label="测评日期："> -->
        <!-- <Row> -->
        <!-- <DatePicker
                type="date"
                placeholder="请选择日期"
                @on-change="changStartTime"
                size="large"
                v-model="searchItem.startDate"
                :options="optionsForDate"
                :editable="false"
                class="date"
              ></DatePicker>
              <span class="timespan">至</span>
              <DatePicker
                class="date"
                type="date"
                placeholder="请选择日期"
                @on-change="changEndTime"
                size="large"
                v-model="searchItem.endDate"
                :options="optionsForDate"
                :editable="false"
              ></DatePicker> -->
        <!-- </Row> -->
        <!-- </FormItem> -->
        <!-- </div> -->
        <!-- </i-col> -->
        <!-- <i-col span="6"> -->
        <!-- <div class="formitem-box">
            <FormItem>
              <Button type="primary" size="large" @click="search()"
                >查询
              </Button>
              <Button
                size="large"
                @click="handleReset('searchItems')"
                style="margin-left: 20px"
                >重置
              </Button>
            </FormItem>
          </div> -->
        <!-- </i-col> -->
        <!-- </Row> -->
        <!-- </Form> -->
      </div>
      <div class="bt">
        <div class="table-box">
          <!-- :row-class-name="rowClassName" -->
          <Table
            :columns="tabColumns"
            :data="tabData"
            stripe
            border
            ref="selection"
            :loading="tableLoading"
          ></Table>
          <div class="page">
            <Page
              :total="totalCount"
              :current="searchItem.pageNumber + 1"
              :page-size="searchItem.pageSize"
              show-total
              @on-change="changPageNum"
              @on-page-size-change="changPageSize"
            />
          </div>
        </div>
      </div>

      <!--报告详情弹窗-->
      <report-details
        ref="trainstatistics"
        :trainStatisticsData="trainStatisticsData"
        :patientId="trainStatisticsData.patientId"
        @refresh="refresh"
      ></report-details>

      <!--报告详情弹框  class-name="vertical-center-modal reportMod modalHead"-->
      <Modal
        v-model="modal1"
        title="报告详情"
        class-name="vertical-center-modal reportMod modalHead"
        :footer-hide="true"
      >
        <template slot="header">
          <div class="title">报告详情</div>
        </template>
        <div>
          <table>
            <thead>
              <tr>
                <td>测评内容</td>
                <td>得分</td>
                <td>参考值</td>
                <td>结果</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in scaleReport.detailContents"
                :key="index"
              >
                <td>{{ item.projectName }} {{ item.content }}</td>
                <td>{{ item.score }}</td>
                <td>{{ item.reference }}</td>
                <td :class="item.result === '异常' ? '' : ''">
                  {{ item.result }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="modal-desc">
            <h1>测评结果说明：</h1>
            <div
              v-html="item.content"
              v-for="(item, index) in scaleReport.resultContents"
              :key="index"
            ></div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { getCookieDid } from "@js/session.sg";
// import tabarhead from "@c/common/tabarHead";
import reportDetails from "@v/patientend/items/reportDetails"; // 报告详情modal
export default {
  components: {
    // tabarhead,
    reportDetails,
  },
  computed: {
    doctorInfo() {
      return this.$store.state.doctorInfo;
    },
  },
  data() {
    return {
      optionsForDate: {
        disabledDate(date) {
          return (
            (date && date.valueOf() >= Date.now()) ||
            date.valueOf() < new Date(2019, 0, 1)
          );
        },
      },
      isDetailsShow: true, // 报告详情弹框
      modal1: false, // 报告详情弹框
      tableLoading: false,
      delePData: {
        patientName: "",
        patientId: "",
        delePatientModal: false,
      },
      doctorId: "",
      searchItem: {
        groupId: "", // 测评名称
        patientId: "",
        startDate: "",
        endDate: "",
        pageNumber: 0,
        pageSize: 10,
      },
      totalCount: 0, // 总数
      tabColumns: [
        {
          title: "序号",
          width: 70,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              {},
              params.index +
                1 +
                this.searchItem.pageNumber * this.searchItem.pageSize
            );
          },
        },
        {
          title: "姓名",
          key: "patientUserName",
          align: "center",
          width: 150,
        },
        {
          title: "测评日期",
          key: "assessDate",
          width: 200,
          align: "center",
        },
        {
          title: "测评医生",
          key: "doctorName",
          width: 150,
          align: "center",
        },
        {
          title: "测评量表",
          key: "assessName",
          minWidth: 155,
          align: "center",
        },
        {
          title: "测评内容",
          key: "scaleEvacontent",
          align: "center",
          minWidth: 155,
        },
        // {
        //     title: "得分",
        //     key: "scaleScore",
        //     minWidth: 80,
        //     align: "center"
        // },
        // {
        //     title: "参考值",
        //     key: "referenceValue",
        //     align: "center",
        //     minWidth: 100
        // },
        {
          title: "结果",
          key: "assessResultStr",
          align: "center",
          minWidth: 80,
          render: (h, params) => {
            let row = params.row;
            let color;
            if (row.assessResult === 5) {
              // color = "rgba(255, 38, 0, 1)";
              color = "";
            } else {
              // color = "#0E86E7";
              color = "";
            }
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: color,
                    // cursor: "pointer"
                  },
                },
                params.row.assessResultStr
              ),
            ]);
          },
        },
        {
          title: "操作",
          key: "action",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: "#4174f7",
                    cursor: "pointer",
                  },
                  on: {
                    click: () => {
                      this.opModal(
                        params.row.masterId,
                        params.row.groupId,
                        params.row.webRouteDetailReport,
                        params.row.completeForm
                      );
                    },
                  },
                },
                "报告详情"
              ),
              // 2021.9.9 产品确认 暂时注释掉测评详情
              // h(
              //   "span",
              //   {
              //     style: {
              //       color: "#0E86E7",
              //       cursor: "pointer",
              //       marginLeft: "50px",
              //     },
              //     on: {
              //       click: () => {
              //         this.opModalreport(
              //           params.row.masterId,
              //           params.row.webRouteViewData,
              //           params.row.completeForm
              //         );
              //       },
              //     },
              //   },
              //   "测评详情"
              // ),
            ]);
          },
        },
      ],
      // todo 模拟数据
      tabData: [
        // {
        //     patientUserName: "王青青",
        //     assessDate: "2020-05-09",
        //     doctorName: "李医生",
        //     assessName: "ADL Activities of daily living Scale",
        //     scaleEvacontent: "日常生活能力",
        //     scaleScore: "5.0",
        //     referenceValue: "7",
        //     assessResultStr: "异常"
        // }
      ],
      groupList: [],
      scaleReport: {}, // 报告详情
      planDetail: {}, // 方案详情
      // 测评数据
      trainStatisticsData: {
        patientId: "",
        t: "",
      }, // 训练日志数据
      patientModel: false,
    };
  },
  methods: {
    // 获取表格数据
    getTableData() {
      this.tableLoading = true;
      let data = this.searchItem;
      this.$http
        .gets("scaleMaster/queryByPId", this.$http.adornParams(data, false))
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            this.tabData = res.data;
            this.totalCount = res.count;
            this.tableLoading = false;
          } else {
            this.$Message.error(res.msg);
            this.tableLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.tableLoading = false;
        });
    },
    // 获取测评名称列表
    getGroupList() {
      let data = {
        patientId: this.searchItem.patientId,
      };
      this.$http
        .post(
          "scaleMaster/getScaleNameListByPId",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          if (res.code === 1) {
            // todo
            this.groupList = res.data;
            console.log("groupList", this.groupList);
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // todo报告详情数据
    opModal(masterId, groupId, webRouteDetailReport, completeForm) {
      if (
        groupId === "236" ||
        groupId === "237" ||
        groupId === "238" ||
        groupId === "239" ||
        groupId === "240" ||
        groupId === "241" ||
        groupId === "24120868141551631"
      ) {
        this.$Message.warning("该报告详情正在优化中");
        return;
      }
      if (completeForm !== 1) {
        this.$Message.warning("测评未完成");
        return;
      }
      console.log(webRouteDetailReport);
      if (webRouteDetailReport) {
        this.$router.push({
          path: webRouteDetailReport,
          query: { masterId: masterId },
        });
        // let ua = navigator.userAgent.toLowerCase();
        // if (/android/.test(ua)) {
        //   this.$router.push({
        //     path: webRouteDetailReport,
        //     query: { masterId: masterId },
        //   });
        //   return;
        // }
        // window.open(webRouteDetailReport + "?masterId=" + masterId);
      } else {
        this.masterId = masterId;
        this.$router.push({
          path: "/main/reportDetails",
          query: {
            masterId: masterId,
            groupId: groupId,
          },
        });
      }

      // 获取报告详情
      // let data1 = {
      //     masterId: masterId,
      //     utype: 'D'
      // }
      // this.$http.gets('report/getResult', this.$http.adornParams(data1, false))
      //     .then((res) => {
      //         console.log(res);
      //         if (res.code === 1) {
      //             // todo
      //             this.scaleReport = res.data
      //         } else {
      //             this.$Message.error(res.msg);
      //         }
      //     }).catch(err => {
      //     console.log(err);
      // });
      // this.modal1 = true
    },
    // 开始时间选中
    changStartTime(value) {
      this.searchItem.startDate = value;
    },
    // 结束时间选中
    changEndTime(value) {
      if (this.searchItem.startDate > value) {
        this.searchItem.endDate = "";
        return this.$Message.error("开始时间不能大于结束日期");
      }
      this.searchItem.endDate = value;
    },
    // 分页改变页数
    changPageNum(num) {
      this.searchItem.pageNumber = num - 1;
      this.getTableData();
    },
    // 分页改变条数
    changPageSize(size) {
      this.searchItem.pageSize = size;
      this.getTableData();
    },
    // 点击查询
    search() {
      this.searchItem.pageNumber = 0;
      this.getTableData();
    },
    // 点击重置
    handleReset(name) {
      this.$refs[name].resetFields();
      this.searchItem.startDate = "";
      this.searchItem.endDate = "";
    },
    // todo点击测评报告、测评详情
    opentrainStatisticsModel(patientId) {
      this.trainStatisticsData.patientId = patientId;
      this.trainStatisticsData.t = new Date().getTime();
      this.$refs.trainstatistics.opentrainStatisticsModal();
    },
    opModalreport(masterId, webRouteViewData, completeForm) {
      if (completeForm !== 1) {
        this.$Message.warning("测评未完成");
        return;
      }
      if (
        webRouteViewData === null ||
        webRouteViewData === "" ||
        webRouteViewData === undefined
      ) {
        return this.$Message.warning("该测评详情正在优化中");
      } else {
        window.open(webRouteViewData + "?masterId=" + masterId);
      }
      // let routeData = this.$router.resolve({
      //   path: webRouteViewData,
      //   query: {
      //     masterId: masterId,
      //   },
      // });
      // window.open(routeData.href, "_blank");
    },
    refresh() {
      console.log();
    },
  },
  mounted() {
    this.searchItem.patientId = this.$store.state.patientInfo.patientId;
    this.getTableData();
    this.getGroupList();
  },
};
</script>

<style scoped lang="scss">
// @import "@css/asyncModal.scss";

.ivu-modal {
  .title {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #4174f7;
  }
}
/deep/ .ivu-page-item-active {
  border-color: #4174f7;
  a {
    color: #4174f7;
  }
}

/deep/ .ivu-date-picker-rel {
  cursor: pointer;
}

/deep/ .ivu-date-picker {
  cursor: pointer;
}

.container {
  position: relative;
  background-color: #fafafa;
  height: 100% !important;

  .card {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    // height: 794px;
    height: 800px; // 为了显示底部产品信息
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    // line-height: 1;
    .hd {
      padding: 24px;
      border-radius: 40px 40px 0 0;
      border-bottom: 1px solid #ededed;
      h1 {
        font-size: 24px;
        font-weight: bolder;
        color: #333333;
      }
      /deep/ .ivu-form {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .formitem-box {
          &:first-child {
            .ivu-form-item-content {
              width: 160px !important;

              .ivu-select-selection {
                width: 100% !important;
              }
            }
          }
        }
        .formitem-box:nth-child(2) {
          margin-left: 50px;
          margin-right: -50px;
        }

        .ivu-form-item {
          margin-bottom: unset;

          .ivu-form-item-label {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            white-space: nowrap;
          }

          .timespan {
            margin: 0 15px;
            font-size: 20px;
            color: #5f5f5f;
          }

          .ivu-select-selection {
            width: 0px;
          }
        }
      }
    }

    .bt {
      padding: 25px 24px 0 24px;
      height: 85%;

      .table-box {
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        /deep/ .ivu-table {
          font-size: 20px;
          font-weight: 400;
          color: #535353;

          thead {
            tr {
              th {
                // background: rgba(14, 134, 231, 0.11) !important;#E9F1FD
                background: #e7f2fb !important;
                .ivu-table-cell {
                  padding: 14px 0;
                  font-size: 20px !important;
                  font-weight: 500;
                  color: #535353;
                  overflow: visible !important;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                height: 55px;
                .ivu-table-cell {
                  font-size: 20px !important;
                  font-weight: 400;
                  color: #535353;
                  overflow: visible !important;
                }
              }
            }
          }
        }

        /deep/ .page {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 15px 0;
        }
      }
    }
  }
}

/deep/ .reportMod {
  .ivu-modal {
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left: -500px;
    // transform: translateX(-50%);
    width: 1000px !important;
    height: 560px;

    .ivu-modal-body {
      padding: 40px 80px;
      overflow: auto;

      table {
        width: 100%;
        border: 1px solid rgba(199, 199, 199, 1);

        tr {
          td {
            border: 1px solid rgba(199, 199, 199, 1);
            padding: 20px;
            text-align: center;
          }
        }

        thead {
          tr {
            td {
              font-size: 20px;
              font-weight: 400;
              color: #606060;
              background: rgba(15, 134, 232, 0.11);
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 20px;
              font-weight: 300;
              color: #606060;
            }

            // .reds {
            //   color: rgba(255, 3, 27, 1) !important;
            // }
          }
        }
      }

      .modal-desc {
        padding: 18px 0 30px 0;
        border-bottom: 1px solid rgba(222, 222, 222, 1);

        h1 {
          font-size: 20px;
          font-weight: 500;
          color: rgba(52, 52, 52, 1);
        }

        div {
          font-size: 20px;
          font-weight: 500;
          color: rgba(52, 52, 52, 1);
        }
      }
    }
  }
}
.page {
  /deep/ .ivu-page {
    .ivu-page-item:hover {
      border: 1px solid #dcdee2;
      a {
        color: #515a6e;
      }
    }
  }
}
</style>
