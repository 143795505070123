<template>
  <div class="container">
    <!-- <div class="label">
            <span class="label1">初级</span>
            <span class="label2">中级</span>
            <span class="label3">高级</span>
        </div> -->
    <div id="line-location"></div>
  </div>
</template>

<script>
// import echarts from "echarts";
export default {
  data() {
    return {
      drawArr: [],
      // 记录当前坐标currentIndex
      currentIndex: "",
      options: {
        grid: {
          top: 100,
          bottom: 100,
          left: 100,
          right: 100,
          containLabel: true,
        },
        xAxis: [
          {
            name: "得分",
            type: "value",
            boundaryGap: false,
            // Interval: 1000,
            data: [0, 1000, 2000, 3000, 4000, 5000, 6000],
            axisTick: {
              show: false,
              interval: 1,
            },
            axisLabel: {
              // interval: 1
              showMinLabel: false,
              showMaxLabel: false,
            },
          },
        ],
        yAxis: [
          {
            name: "分布",
            type: "value",
            // min: 0,
            // max: 100,
            axisTick: {
              // y轴刻度线
              show: false,
            },
            axisLabel: {
              // interval: 1,
              showMaxLabel: false,
              showMinLabel: false,
              show: false,
            },
            // minInterval: 100,
          },
        ],
        series: [
          {
            name: "分布",
            type: "line",
            stack: "总量",
            color: "rgba(65, 116, 247, 1)",
            // areaStyle: {},
            smooth: true,
            mutation: true,
            symbolSize: 0,
            data: [],
          },
        ],
      },
      yData: [],
      xData: [],
      sore: "",
      myChart: "",
    };
  },

  props: ["chartData", "arrDataList"],

  components: {},

  computed: {
    chartInfo() {
      return this.chartData;
    },
  },

  created() {
    // //    this.buildChart()
    // this.chartData = {
    //   rangelow: 2170.77,
    //   rangeHigh: 5973.36,
    //   averageScore: 4072.57,
    //   standardDeviation: 1901.8,
    //   trainScore: 3300,
    // };
  },

  async mounted() {
    // console.log(this.arrDataList);
    this.myChart = this.$echarts.init(document.getElementById("line-location"));
    this.myChart.setOption(this.options);
    // this.buildChart();
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  watch: {
    chartData: {
      handler(value) {
        // console.log(value);
        // 动态计算 为几倍的平均差
        this.sore =
          // (得分 - 平均分)/平均差 取绝对值、取整后  +1
          Math.abs(
            parseInt(
              (value.trainScore - parseFloat(this.arrDataList.averageScore)) /
                parseFloat(this.arrDataList.standardDeviation)
            )
          ) + 1;
        if (this.sore < 3) {
          this.sore = 3;
        }
        // console.log(this.sore);
        // 划线的全部点[x, y]
        this.getAllLineDots();
        // console.log("插入当前位置后：", this.drawArr);
        // 使用计算出来的值来划线
        let chartDataList = this.drawArr;
        let currentIndex = this.currentIndex;
        // console.log(chartDataList);
        // 给当前的值添加样式
        let styleTemplate = {
          value: chartDataList[currentIndex],
          symbol: "circle",
          symbolSize: 8,
          itemStyle: {
            color: "white",
            borderColor: "rgba(65, 116, 247, 1)",
            borderWidth: 4,
          },
          label: {
            show: true,
            position: "top",
            distance: 10,
            formatter: "{a|您的位置}",
            rich: {
              a: {
                fontSize: 14,
                align: "center",
                color: "#fff",
                backgroundColor: "rgba(65, 116, 247, 1)",
                height: 25,
                width: 68,
                borderRadius: 5,
              },
            },
          },
        };
        // 使用修改后（添加了样式）的元素替换原来的元素
        chartDataList[currentIndex] = styleTemplate;

        // 给options中的data赋值
        this.options.series[0].data = chartDataList;
        // console.log("修改后的chartDataList", chartDataList);
        // 计算出几个markArea的坐标
        // 取出4个点 0 中间点1 中间点2 最大值
        let x1 = 0;
        let x2 = parseFloat(this.arrDataList.rangeLow);
        let x3 = parseFloat(this.arrDataList.rangeHigh);
        let max =
          parseFloat(this.arrDataList.averageScore) +
          this.sore * parseFloat(this.arrDataList.standardDeviation);
        let x4 =
          max < this.chartInfo.trainScore ? this.chartInfo.trainScore : max;
        this.options.series[0].markArea = {
          data: [
            [
              {
                name: "初级",
                xAxis: x1,
                label: {
                  show: true,
                  position: "top", // markArea中文字（name）位置
                  offset: [0, -30], // markArea中文字（name）显示在位置基础上x、y轴偏移
                  color: "#FFC600", // markArea中文字（name）颜色
                  fontSize: 0,
                  fontWeight: 500,
                },
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "#fff4cb",
                    },
                    {
                      offset: 1,
                      color: "#fff",
                    },
                  ]),
                },
              },
              {
                xAxis: x2,
              },
            ],
            [
              {
                name: "中级",
                xAxis: x2,
                label: {
                  show: true,
                  position: "top", // markArea中文字（name）位置
                  offset: [0, -30], // markArea中文字（name）显示在位置基础上x、y轴偏移
                  color: "#5AD99F", // markArea中文字（name）颜色
                  fontSize: 0,
                  fontWeight: 500,
                },
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "#def7ec",
                    },
                    {
                      offset: 1,
                      color: "#fff",
                    },
                  ]),
                },
              },
              {
                xAxis: x3,
              },
            ],
            [
              {
                name: "高级",
                xAxis: x3,
                label: {
                  show: true,
                  position: "top", // markArea中文字（name）位置
                  offset: [0, -30], // markArea中文字（name）显示在位置基础上x、y轴偏移
                  color: "#0A91F0", // markArea中文字（name）颜色
                  fontSize: 0,
                  fontWeight: 500,
                },
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "#cee9fc",
                    },
                    {
                      offset: 1,
                      color: "#fff",
                    },
                  ]),
                },
              },
              {
                xAxis: x4,
              },
            ],
          ],
        };
        this.myChart.setOption(this.options);
      },
    },
  },

  methods: {
    getAllLineDots() {
      // 计算最小值
      let min =
        parseFloat(this.arrDataList.averageScore) -
        this.sore * parseFloat(this.arrDataList.standardDeviation);
      // 计算最大值
      let max =
        parseFloat(this.arrDataList.averageScore) +
        this.sore * parseFloat(this.arrDataList.standardDeviation);
      this.options.xAxis[0].max = parseInt(max);
      if (parseInt(min) < 0) {
        min = 0;
      }
      this.options.xAxis[0].min = parseInt(min);
      // 循环 18个数值
      let minList = [];
      let maxList = [];
      let pxLi = document.querySelectorAll("#line-location")[0].clientWidth;
      for (let index = 0; index < pxLi; index++) {
        // 最小值至中间值平均值
        minList.push(
          min +
            ((parseFloat(this.arrDataList.averageScore) - min) * index) / pxLi
        );
        // 最大值至中间值平均值
        maxList.push(
          ((max - parseFloat(this.arrDataList.averageScore)) * (index + 1)) /
            pxLi +
            parseFloat(this.arrDataList.averageScore)
        );
      }
      // 添加至 xData 数组
      this.xData = minList.concat(maxList);
      // console.log(this.xData);
      let totalCount = 0;
      // 给 xData数值从小至大排序
      this.xData.sort(function (a, b) {
        return a - b;
      });
      // 计算 xData 数据总和
      this.xData.forEach((element) => {
        totalCount += element;
      });
      // totalCount 总和数据为 18个数值总和  加上需要插入的平均分的值和得分的值 总共20个数值总和
      totalCount +=
        parseFloat(this.arrDataList.averageScore) + this.chartInfo.trainScore;
      // 带入正态分布公式
      this.xData.forEach((element) => {
        let probability = this.normalDistributionfun(
          element,
          // 平均数
          parseFloat(this.arrDataList.averageScore),
          //  标准差
          parseFloat(this.arrDataList.standardDeviation)
        );
        // y轴坐标点
        this.yData.push(Math.round(probability * totalCount * 100) / 100);
      });
      // console.log("y轴数据", this.yData, "x轴数据", this.xData);
      // 一维数组转二位数组
      this.xData.forEach((item, i) => {
        let arr1 = [];
        arr1.push(item);
        arr1.push(this.yData[i]);
        this.drawArr.push(arr1);
      });
      // console.log("二维数组", this.drawArr);
      // 获取 平均值 插入 drawArr 数组中的位置
      let currentIndex = this.getCurrentIndex(
        parseFloat(this.arrDataList.averageScore)
      );
      // 获取 平均值 插入时的Y轴坐标
      let averageScoreY =
        Math.round(
          this.normalDistributionfun(
            parseFloat(this.arrDataList.averageScore),
            parseFloat(this.arrDataList.averageScore),
            parseFloat(this.arrDataList.standardDeviation)
          ) *
            totalCount *
            100
        ) / 100;
      // 平均值 插入 drawArr 数组
      this.getNewArr(currentIndex, [
        parseFloat(this.arrDataList.averageScore),
        averageScoreY,
      ]);

      // 获取 得分 插入 drawArr 数组中的位置
      let trainScoreIndex = this.getCurrentIndex(this.chartInfo.trainScore);
      this.currentIndex = trainScoreIndex;
      // 获取 得分 插入时的Y轴坐标
      let trainScoreY =
        Math.round(
          this.normalDistributionfun(
            this.chartInfo.trainScore,
            parseFloat(this.arrDataList.averageScore),
            parseFloat(this.arrDataList.standardDeviation)
          ) *
            totalCount *
            100
        ) / 100;
      // 得分 插入 drawArr 数组
      this.getNewArr(trainScoreIndex, [this.chartInfo.trainScore, trainScoreY]);
    },
    // 随机数
    // getRndInteger(min, max) {
    //   return Math.floor(Math.random() * (max - min)) + min;
    // },
    // 正态公式 计算出来的数值为 y轴坐标
    normalDistributionfun(x, mean, stdev) {
      return (
        (1 / (Math.sqrt(2 * Math.PI) * stdev)) *
        Math.exp((-1 * ((x - mean) * (x - mean))) / (2 * stdev * stdev))
      );
    },

    // 比当前值大的那一项的索引
    getCurrentIndex(current) {
      if (this.drawArr[this.drawArr.length - 1][0] < current) {
        return this.drawArr.length;
      } else {
        for (let index = 0; index < this.drawArr.length; index++) {
          // console.log("遍历drawArr", this.drawArr[index][0]);
          if (this.drawArr[index][0] > current) {
            // console.log("index位置", index);
            return index;
          }
        }
      }
    },
    // 根据索引向数组中插入数据
    getNewArr(index, current) {
      // console.log("getNewArr插入数组", index, current);
      return this.drawArr.splice(index, 0, current);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 800px;
  height: 500px;
  #line-location {
    width: 100%;
    height: 100%;
  }
  .label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    z-index: 999;
  }
  .label1 {
    color: #f2e890;
  }
  .label2 {
    color: #c6eb9b;
  }
  .label3 {
    color: #87c3ca;
  }
}
</style>
