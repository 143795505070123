<template>
  <div class="video">
    <div class="hezi">
      <video-player
        v-if="playerOptions['sources'][0].src !== ''"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="playsinline"
        x5-video-player-type="h5-page"
      >
      </video-player>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainVideo",
  props: [
    "trainScale",
    "videoUrl",
    "againTrainFlg",
    "trainInfoDesc",
    "trainIntroduce",
  ],
  data: function () {
    return {
      videoPlayStatus: 0,
      videoId: "", // 获取上级页面传递的文章id
      detail: {
        videoTitle: "",
        releaseDate: "",
      }, // 详情内容
      VideoList: "", // 视频列表
      playerOptions: {
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
        autoplay: true, // 是否自动播放
        muted: false, // 是否默认静音
        language: "zh-CN",
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        loop: false, // 导致视频一结束就重新开始。
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            // mp4
            src: this.videoUrl,
            // webm
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
            // src: "https://sgjk.oss-cn-beijing.aliyuncs.com/courses/202007210071.mp4"
            // src: require("@/assets/img/patientend/2jtdW5XxBA.webm")
          },
        ], // 视频地址
        poster: "", // 封面
        controlBar: {
          //                    timeDivider : true,//当前时间和持续时间的分隔符
          //                    durationDisplay : true,//显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 全屏按钮
        },
      },
      videoShow: false,
      logTime: "",
      value1: false,
    };
  },
  computed: {
    playsinline() {
      let ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (
        ua.match(/tencenttraveler/) !== null ||
        ua.match(/qqbrowse/) !== null
      ) {
        return false;
      } else {
        // ios端
        return true;
      }
    },
  },
  watch: {
    videoUrl: {
      handler(value) {
        if (value !== "") {
          this.$nextTick(() => {
            this.player = this.$refs.videoPlayer.player;
            this.autoplay();
          });
        }
      },
      immediate: true,
    },
    player: {
      handler() {
        if (this.player) {
          this.autoplay();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 自动播放schedId  trainId
    autoplay() {
      if (this.player) {
        // setTimeout(() => {
        // }, 100);
        // this.player.play();
        let data = {
          trainId: this.trainScale.trainId,
          schedId: this.trainScale.schedId,
          patientPlanId: this.trainScale.patientPlanId,
          // 如果是再练一遍 添加标识符againTrainFlg
          againTrainFlg: this.againTrainFlg,
        };
        // 播放时调用的后台接口，不用处理
        this.$http
          .post(
            "patient/plan/info/updateVideoFinishStatus",
            this.$http.adornData(data, false, "form")
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@css/style.scss";
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 32px;
}
.hezi {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .video-player {
    height: 100% !important;
  }
}
/deep/ .vjs-menu-content {
  width: 80% !important;
}

/deep/ .ivu-drawer-header {
  .ivu-drawer-header-inner {
    font-size: 24px;
    font-weight: 500;
    color: #333;
  }
}
/deep/ .ivu-drawer {
  width: 400px !important;
}
/deep/ .ivu-drawer-body {
  p {
    font-size: 20px;
    font-weight: 400;
    color: #333;
    line-height: 32px;
    opacity: 0.7;
  }
  button {
    background: #4171f7;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 12px 32px;
    margin-top: 30px;
    margin-left: 100px;
    cursor: pointer;
  }
}
</style>
