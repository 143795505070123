<template>
  <div class="pr-container">
    <div class="card">
      <div class="card-top">
        <!--我的训练数据-->
        <div class="trainData">
          <div class="title">
            <h1>我的训练数据</h1>
            <p>最近训练日期：{{ MyTrainData.latestTrainDate }}</p>
          </div>
          <div class="num">
            <div class="one">
              <h1>{{ MyTrainData.cumulativeTrainDays }}</h1>
              <p>累计训练（天）</p>
            </div>
            <div class="one">
              <h1>{{ MyTrainData.unlockTrainStyle }}</h1>
              <p>解锁训练（款）</p>
            </div>
            <div class="one">
              <h1>{{ MyTrainData.trainNumber }}</h1>
              <p>训练次数（次）</p>
            </div>
            <div class="one">
              <h1>{{ MyTrainData.trainDurationMinutes }}</h1>
              <p>训练时长（分钟）</p>
            </div>
          </div>
        </div>
        <!--日历-->
        <div class="calendar">
          <div class="calendarClick" @click="fangda"></div>
          <div class="calendar-head">
            <div class="left">
              <img src="../../assets/img/patientend/left-icon.png" alt="" />
              <p>{{ year }} 年 {{ month }} 月</p>
              <img src="../../assets/img/patientend/right-icon.png" alt="" />
            </div>
            <div class="right">
              <div class="one">
                <img src="../../assets/img/patientend/today-icon.png" alt="" />
                <p>今天</p>
              </div>
              <div class="one">
                <img src="../../assets/img/patientend/delay-icon.png" alt="" />
                <p>延期</p>
              </div>
              <div class="one">
                <img src="../../assets/img/patientend/train-icon.png" alt="" />
                <p>训练</p>
              </div>
            </div>
          </div>
          <div class="calendar-body">
            <div class="week">
              <p>一</p>
              <p>二</p>
              <p>三</p>
              <p>四</p>
              <p>五</p>
              <p>六</p>
              <p style="margin-right: 0">日</p>
            </div>
            <!--注：数组元素说明：[[日期的日, 日期标记类型, 日完成多少项]]
注：日期标记类型：0.未训练 1.今天 2.训练 3.延期-->
            <div class="day">
              <p
                :class="
                  item[1] === 1
                    ? 'today'
                    : item[1] === 2
                    ? 'train'
                    : item[1] === 3
                    ? 'delay'
                    : ''
                "
                v-for="(item, index) in smallDatList"
                :key="index"
              >
                {{ item[0] }}
                <span
                  class="label"
                  v-if="item[2] !== 0 && item[2] !== 1 && item[1] === 2"
                >
                  <span class="num"> {{ item[2] }}项 </span>
                </span>
                <img
                  src="../../assets/img/patientend/duigou.png"
                  alt=""
                  v-if="item[1] === 2"
                />
              </p>
            </div>
          </div>
          <div class="calendar-foot">
            <img
              src="../../assets/img/patientend/fangda.png"
              alt=""
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>
      <div class="card-bottom">
        <div class="heads">
          <div class="title">
            <h1>训练明细</h1>
          </div>
          <Form :model="searchItem" ref="searchItems">
            <FormItem label="方案类别" prop="planType">
              <Select
                v-model="searchItem.planType"
                size="large"
                style="width: 150px"
              >
                <Option
                  :value="item.patientPlanName"
                  v-for="(item, index) in groupList"
                  :key="index"
                  >{{ item.patientPlanName }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="训练时间">
              <DatePicker
                type="date"
                placeholder="请选择日期"
                @on-change="changStartTime"
                size="large"
                v-model="searchItem.startDate"
                :options="optionsForDate"
                :editable="false"
              ></DatePicker>
              <span class="timespan">至</span>
              <DatePicker
                type="date"
                placeholder="请选择日期"
                @on-change="changEndTime"
                size="large"
                v-model="searchItem.endDate"
                :options="optionsForDate"
                :editable="false"
              ></DatePicker>
            </FormItem>
            <FormItem>
              <Button type="primary" size="large" @click="search()"
                >查询</Button
              >
              <Button
                size="large"
                @click="handleReset('searchItems')"
                style="margin-left: 10px"
                >重置</Button
              >
            </FormItem>
          </Form>
        </div>
        <div class="table-box">
          <!-- :row-class-name="rowClassName" -->
          <Table
            :columns="tabColumns"
            :data="tabData"
            stripe
            border
            ref="selection"
            :loading="tableLoading"
          ></Table>
          <div class="page">
            <Page
              :total="totalCount"
              :current="searchItem.pageNumber + 1"
              :page-size="searchItem.pageSize"
              show-total
              @on-change="changPageNum"
              @on-page-size-change="changPageSize"
              ref="page"
            />
          </div>
        </div>
      </div>
      <!--报告详情弹窗-->
      <report-details
        ref="trainstatistics"
        :trainStatisticsData="trainStatisticsData"
        :patientId="trainStatisticsData.patientId"
        @refresh="refresh"
      ></report-details>

      <!--报告详情弹框  class-name="vertical-center-modal reportMod modalHead"-->
      <Modal
        v-model="modal1"
        title="报告详情"
        class-name="vertical-center-modal reportMod modalHead"
        :footer-hide="true"
      >
        <template slot="header">
          <div class="title">报告详情</div>
        </template>
        <div>
          <table>
            <thead>
              <tr>
                <td>序号</td>
                <td>名称</td>
                <td>训练日期</td>
                <td>本次得分</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in scaleReport" :key="index">
                <td>{{ index }}</td>
                <td>{{ item.trainName }}</td>
                <td>{{ item.trainDateTime }}</td>
                <td>{{ item.trainScore }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <!--日历弹框-->
      <Modal
        v-model="calendarModal"
        :closable="false"
        class-name="calendarModal"
        :footer-hide="true"
        :mask-closable="false"
      >
        <div class="calendarBox">
          <div class="pre">
            <img
              :src="preMonth === currentMonth ? noPreImg : preImg"
              alt=""
              style="cursor: pointer"
              @click="pickPre(currentYear, currentMonth)"
            />
          </div>
          <div class="next">
            <img
              :src="currentMonth === nextMonth ? noNextImg : nextImg"
              alt=""
              style="cursor: pointer"
              @click="pickNext(currentYear, currentMonth)"
            />
          </div>
          <div class="calendarBox-head">
            <div class="left">
              <h1>{{ currentYear }} 年 {{ currentMonth }} 月</h1>
            </div>
            <div class="right">
              <div class="one">
                <img src="../../assets/img/patientend/big-blue.png" alt="" />
                <p>今天</p>
              </div>
              <div class="one">
                <img src="../../assets/img/patientend/big-red.png" alt="" />
                <p>延期</p>
              </div>
              <div class="one">
                <img src="../../assets/img/patientend/big-duigou.png" alt="" />
                <p>训练</p>
              </div>
            </div>
          </div>
          <div class="calendarBox-body">
            <div class="week">
              <p>一</p>
              <p>二</p>
              <p>三</p>
              <p>四</p>
              <p>五</p>
              <p>六</p>
              <p>日</p>
            </div>
            <div class="day">
              <p
                :class="
                  item[1] === 1
                    ? 'today'
                    : item[1] === 2
                    ? 'train'
                    : item[1] === 3
                    ? 'delay'
                    : ''
                "
                v-for="(item, index) in dayList"
                :key="index"
              >
                {{ item[0] }}
                <span
                  class="label"
                  v-if="item[2] !== 0 && item[2] !== 1 && item[1] === 2"
                >
                  <span class="num"> {{ item[2] }}项 </span>
                </span>
                <img
                  src="../../assets/img/patientend/duigou.png"
                  alt=""
                  v-if="item[1] === 2"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="closeBth">
          <img
            src="../../assets/img/patientend/close-icon.png"
            alt=""
            style="cursor: pointer"
            @click="close"
          />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { getCookieDid } from "@js/session.sg";
import reportDetails from "@v/patientend/items/reportDetails"; // 报告详情modal
import "../../assets/font/bold/font.css";
export default {
  components: {
    reportDetails,
  },
  computed: {
    doctorInfo() {
      return this.$store.state.doctorInfo;
    },
  },
  data() {
    return {
      optionsForDate: {
        disabledDate(date) {
          return (
            (date && date.valueOf() >= Date.now()) ||
            date.valueOf() < new Date(2019, 0, 1)
          );
        },
      },
      isDetailsShow: true, // 报告详情弹框
      modal1: false, // 报告详情弹框
      tableLoading: false,
      delePData: {
        patientName: "",
        patientId: "",
        delePatientModal: false,
      },
      doctorId: "",
      searchItem: {
        patientId: "", // 测评名称
        pageNumber: 0,
        pageSize: 7,
        planType: "",
        startDate: "",
        endDate: "",
      },
      totalCount: 0, // 总数
      tabColumns: [
        {
          title: "序号",
          width: 90,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              {},
              params.index +
                1 +
                this.searchItem.pageNumber * this.searchItem.pageSize <
                10
                ? "0" +
                    (params.index +
                      1 +
                      this.searchItem.pageNumber * this.searchItem.pageSize)
                : params.index +
                    1 +
                    this.searchItem.pageNumber * this.searchItem.pageSize
            );
          },
        },
        {
          title: "姓名",
          key: "patientName",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h(
              "p",
              {
                style: {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
              },
              params.row.patientName
            );
          },
        },
        {
          title: "方案类别",
          key: "trainFlag",
          minWidth: 200,
          align: "center",
        },
        {
          title: "训练开始时间",
          key: "createTime",
          minWidth: 180,
          align: "center",
        },
        {
          title: "训练结束时间",
          key: "endTime",
          minWidth: 180,
          align: "center",
        },
        {
          title: "训练状态",
          key: "trainStatusName",
          minWidth: 120,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          minWidth: 60,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: "#4174f7",
                    cursor: "pointer",
                  },
                  on: {
                    click: () => {
                      this.opModal(params.row.trainLogsId);
                    },
                  },
                },
                "详情"
              ),
            ]);
          },
        },
      ],
      // todo 模拟数据
      tabData: [],
      groupList: [],
      scaleReport: {}, // 报告详情
      planDetail: {}, // 方案详情
      // 测评数据
      trainStatisticsData: {
        patientId: "",
        t: "",
      }, // 训练日志数据
      patientModel: false,
      year: "", // 当前年 （外层小弹框）不改变的值
      month: "", // 当前月 （外层小弹框）不改变的值
      currentYear: "", // 当前年
      currentMonth: "", // 当前月
      nextMonth: "", // 右侧是否可点击月份 （当前月）
      preMonth: "", // 左侧是否可点击月份
      currentWeek: "", // 当前周
      dayList: [],
      smallDatList: [],
      calendarModal: false,
      preImg: require("../../assets/img/patientend/pre-icon.png"),
      noPreImg: require("../../assets/img/patientend/no-pre-icon.png"),
      nextImg: require("../../assets/img/patientend/next-icon.png"),
      noNextImg: require("../../assets/img/patientend/no-next-icon.png"),
      MyTrainData: {}, // 我的训练数据
    };
  },
  methods: {
    //  我的训练数据
    getMyTrainData() {
      this.$http
        .post(
          "patient/train/my_data",
          this.$http.adornData({}, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            this.MyTrainData = res.data;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //  日历请求天
    getDayList(week) {
      let data = {
        year: this.currentYear,
        month: this.currentMonth,
      };
      this.$http
        .post(
          "patient/train/calendar",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            if (week === 0) {
              this.currentWeek = 7;
            }
            // 周日
            for (let i = 0; i < this.currentWeek - 1; i++) {
              res.data.unshift([]);
            }
            this.dayList = res.data;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTableData() {
      this.tableLoading = true;
      let data = this.searchItem;
      this.$http
        .post(
          "/patient/log/queryPatientTrainLog",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log("queryPatientTrainLog", res);
          if (res.code === 1) {
            // todo
            this.tabData = res.data;
            this.totalCount = res.count;
            this.tableLoading = false;
          } else {
            this.$Message.error(res.msg);
            this.tableLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.tableLoading = false;
        });
    },
    // 获取测评名称列表
    getGroupList() {
      let data = {
        patientId: this.searchItem.patientId,
      };
      this.$http
        .post(
          "patient/log/planName",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            this.groupList = res.data;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // todo报告详情数据
    opModal(trainLogId) {
      // 获取报告详情
      console.log("trainLogId", trainLogId);
      this.$router.push({
        path: "/main/recordDetails",
        query: { trainLogId: trainLogId },
      });
    },
    // 开始时间选中
    changStartTime(value) {
      if (this.searchItem.endDate !== "") {
        if (this.searchItem.endDate < value) {
          this.searchItem.startDate = "";
          return this.$Message.error("开始时间不能大于结束日期");
        }
      }
      this.searchItem.startDate = value;
    },
    // 结束时间选中
    changEndTime(value) {
      if (value !== "") {
        if (this.searchItem.startDate > value) {
          this.searchItem.endDate = "";
          return this.$Message.error("开始时间不能大于结束日期");
        }
      }
      this.searchItem.endDate = value;
    },
    // 分页改变页数
    changPageNum(num) {
      this.searchItem.pageNumber = num - 1;
      this.getTableData();
    },
    // 分页改变条数
    changPageSize(size) {
      this.searchItem.pageSize = size;
      this.getTableData();
    },
    // 点击查询
    search() {
      this.searchItem.pageNumber = 0;
      this.getTableData();
    },
    // 点击重置
    handleReset(name) {
      this.$refs[name].resetFields();
      this.searchItem.startDate = "";
      this.searchItem.endDate = "";
    },
    // todo点击测评报告、测评详情
    opentrainStatisticsModel(patientId) {
      this.trainStatisticsData.patientId = patientId;
      this.trainStatisticsData.t = new Date().getTime();
      this.$refs.trainstatistics.opentrainStatisticsModal();
    },
    refresh() {
      console.log();
    },
    // 点击打开日历弹框
    fangda() {
      let dat = new Date();
      this.currentYear = dat.getFullYear();
      this.currentMonth = dat.getMonth() + 1;
      dat.setDate(1);
      this.currentWeek = dat.getDay(); // 周几 1-6  0是周日
      this.getDayList(this.currentWeek);
      this.calendarModal = true;
    },
    // 点击关闭日历弹框
    close() {
      this.calendarModal = false;
    },
    pickPre: function (year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      if (this.preMonth === this.currentMonth) {
        return;
      }
      let d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    pickNext: function (year, month) {
      if (month === this.nextMonth) {
        return;
      }
      let d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      let y = year;
      let m = month;
      if (m < 10) m = "0" + m;
      let d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    initData: function (cur) {
      let date = new Date(cur);
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      this.currentWeek = date.getDay(); // 周几 1-6  0是周日
      this.getDayList(this.currentWeek);
    },
  },
  mounted() {
    this.searchItem.patientId = this.$store.state.patientInfo.patientId;
    this.getTableData();
    this.getGroupList();
    let dat = new Date();
    this.currentYear = dat.getFullYear();
    this.currentMonth = dat.getMonth() + 1;
    this.year = dat.getFullYear();
    this.month = dat.getMonth() + 1;
    if (this.currentMonth < 6) {
      this.preMonth = this.currentMonth + 6 + 1;
    } else {
      // if (this.currentMonth - 6 < 0) {
      //     this.preMonth = 12 - this.currentMonth;
      // } else {
      //     this.preMonth = this.currentMonth - 6;
      // }
      this.preMonth = this.currentMonth - 6;
    }
    this.nextMonth = dat.getMonth() + 1;
    dat.setDate(1);
    this.currentWeek = dat.getDay(); // 周几 1-6  0是周日
    this.getMyTrainData();
    this.getDayList(this.currentWeek);
    let data = {
      year: this.currentYear,
      month: this.currentMonth,
    };
    this.$http
      .post(
        "patient/train/calendar",
        this.$http.adornData(data, false, "json"),
        this.$http.jsonContent()
      )
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          if (this.currentWeek === 0) {
            this.currentWeek = 7;
          }
          // 周日
          for (let i = 0; i < this.currentWeek - 1; i++) {
            res.data.unshift([]);
          }
          this.smallDatList = res.data;
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped lang="scss">
.ivu-modal {
  .title {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #4174f7;
  }
}
/deep/ .ivu-date-picker {
  cursor: pointer;
}
.colors {
  border-color: #fff !important;
}
.pr-container {
  background: #fafafa;
  height: 100% !important;
  padding: 40px 80px;
  box-sizing: border-box;
  overflow-y: auto;
  .card {
    width: 100%;
    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .trainData {
        width: 1356px;
        height: 270px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        .title {
          margin-left: 24px;
          margin-top: 46px;
          display: flex;
          align-items: center;
          margin-bottom: 50px;
          h1 {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
          }
          p {
            font-size: 20px;
            font-weight: 400;
            color: #999999;
            margin-left: 40px;
          }
        }
        .num {
          margin-left: 140px;
          display: flex;
          align-items: flex-start;
          .one {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 136px;
            &:last-child {
              margin-right: 0;
            }
            h1 {
              font-size: 48px;
              font-weight: bold;
              color: #333333;
              font-family: "bold";
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #999999;
              margin-top: 15px;
            }
          }
        }
      }
      .calendar {
        width: 380px;
        height: 270px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 15px 0;
        box-sizing: border-box;
        position: relative;
        .calendarClick {
          position: absolute;
          left: 0;
          top: 0;
          width: 380px;
          height: 270px;
          cursor: pointer;
          z-index: 99;
        }
        &-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 22px;
          padding: 0 15px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 5px;
              height: 8px;
            }
            p {
              font-size: 13px;
              font-weight: 600;
              color: #333333;
              margin: 0 14px;
            }
          }
          .right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .one {
              display: flex;
              align-items: center;
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
                img {
                  width: 9px;
                  height: 7px;
                }
              }
              img {
                width: 6px;
                height: 6px;
                margin-right: 5px;
              }
              p {
                font-size: 12px;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
        &-body {
          .week {
            padding: 0 34px;
            display: flex;
            align-items: center;
            p {
              width: 26px;
              height: 17px;
              text-align: center;
              line-height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              margin-right: 21px;
            }
          }
          .day {
            padding: 0 13px 0 34px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            p {
              width: 26px;
              height: 26px;
              text-align: center;
              line-height: 26px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              margin-right: 21px;
              margin-top: 5px;
              position: relative;
            }
            /*今天*/
            .today {
              background: #4174f7;
              border-radius: 50%;
              color: #ffffff;
            }
            /*延期*/
            .delay {
              background: #e75e58;
              border-radius: 50%;
              color: #ffffff;
            }
            /*训练过*/
            .train {
              .label {
                position: absolute;
                right: -12px;
                top: -5px;
                display: inline-block;
                /*width: 31px;*/
                height: 10px;
                background: #3bd37b;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                .num {
                  font-size: 12px;
                  font-weight: 400;
                  color: #ffffff;
                  transform: scale(0.4);
                  white-space: normal;
                }
              }
              img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 13px;
                height: 9px;
              }
            }
          }
        }
        &-foot {
          /*margin-top: 3px;*/
          /*padding: 0 15px;*/
          /*display: flex;*/
          /*justify-content: flex-end;*/
          width: 25px;
          height: 25px;
          position: absolute;
          right: 30px;
          bottom: 28px;
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    &-bottom {
      margin-top: 24px;
      width: 1760px;
      height: 586px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      .heads {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px 16px 24px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 25px;
        .title {
          h1 {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
          }
        }
        /deep/.ivu-form {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ivu-form-item {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            .ivu-form-item-label {
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              margin-right: 20px;
            }
            &:first-child {
              .ivu-form-item-content {
                margin-right: 40px;
                width: 226px;
                height: 40px;
                .ivu-select {
                  width: 100% !important;
                  height: 100%;
                  .ivu-select-selection {
                    height: 100% !important;
                    div {
                      width: 100%;
                      height: 100%;
                    }
                    .ivu-select-placeholder {
                      font-size: 20px;
                      font-weight: 400;
                      width: 100%;
                      height: 40px;
                      line-height: 40px;
                    }
                    .ivu-select-selected-value {
                      font-size: 20px;
                      font-weight: 400;
                      color: #999999;
                    }
                  }
                }
              }
            }
            &:nth-child(2) {
              .timespan {
                font-size: 20px;
                font-weight: 400;
                color: #333333;
                margin: 0 8px;
              }
              .ivu-form-item-content {
                margin-right: 40px;
                display: flex;
                align-items: center;
                .ivu-date-picker {
                  width: 210px;
                  height: 40px;
                  .ivu-date-picker-rel {
                    .ivu-icon-ios-calendar-outline {
                      line-height: 40px;
                      color: rgba(65, 116, 247, 1);
                    }
                    .ivu-input-large {
                      width: 100%;
                      height: 40px;
                    }
                  }
                  .ivu-input {
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                  }
                }
              }
            }
            &:last-child {
              .ivu-form-item-content {
                button {
                  border: none;
                  width: 120px;
                  height: 40px;
                  border-radius: 6px;
                  font-size: 20px;
                  font-weight: 400;
                  &:first-child {
                    color: #ffffff;
                    background: #4174f7;
                  }
                  &:last-child {
                    border: 1px solid #4174f7;
                    background: #ffffff;
                    color: #4174f7;
                  }
                }
              }
            }
          }
        }
      }
      .table-box {
        padding: 0 40px;
        /deep/.ivu-table {
          .ivu-table-overflowX {
            overflow: visible;
          }
          thead {
            tr {
              th {
                font-size: 20px;
                font-weight: 500;
                color: #535353;
                padding: 14px 0;
                background: #e7f2fb;
                .ivu-table-cell {
                  overflow: visible;
                }
              }
            }
          }
          tbody {
            overflow: auto;
            table {
              width: 100% !important;
            }
            tr {
              td {
                font-size: 20px;
                font-weight: 400;
                color: #535353;
                padding: 14px 0;
                .ivu-table-cell {
                  overflow: visible;
                }
              }
            }
          }
        }
        .page {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
/deep/ .ivu-page-item-active {
  border-color: #4174f7;
  a {
    color: #4174f7;
  }
}
/deep/.calendarModal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    width: 900px !important;
    height: 560px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    position: relative;
    .closeBth {
      position: absolute;
      bottom: -110px;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 70px;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .ivu-modal-header {
      display: none;
    }
    .ivu-modal-body {
      padding: 0;
    }
    .ivu-modal-content {
      width: 100%;
      height: 100%;
      padding: 58px 30px 0 30px;
      box-sizing: border-box;
    }
    .calendarBox {
      position: relative;
      .pre {
        position: absolute;
        left: 0;
        top: 206px;
        width: 16px;
        height: 32px;
        img {
          width: 16px;
          height: 32px;
        }
      }
      .next {
        position: absolute;
        right: 0;
        top: 206px;
        width: 16px;
        height: 32px;
        img {
          width: 16px;
          height: 32px;
        }
      }
      &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;
        padding: 0 62px 0 105px;
        .left {
          h1 {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .one {
            margin-right: 50px;
            display: flex;
            align-items: center;
            &:last-child {
              margin-right: 0;
              img {
                width: 22px;
                height: 16px;
              }
            }
            img {
              width: 12px;
              height: 12px;
              margin-right: 15px;
            }
            p {
              font-size: 18px;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      &-body {
        .week {
          padding: 0 38px 0 85px;
          display: flex;
          align-items: center;
          p {
            width: 55px;
            height: 36px;
            margin-right: 47px;
            text-align: center;
            line-height: 36px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
        .day {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 38px 0 85px;
          p {
            width: 55px;
            height: 55px;
            margin-right: 47px;
            text-align: center;
            line-height: 55px;
            margin-top: 5px;
            position: relative;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .train {
            .label {
              position: absolute;
              width: 47px;
              height: 21px;
              background: #3bd37b;
              border-radius: 11px;
              display: flex;
              align-items: center;
              justify-content: center;
              right: -15px;
              top: -5px;
              .num {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
            }
            img {
              width: 28px;
              height: 21px;
              position: absolute;
              bottom: 7px;
              right: -5px;
            }
          }
          /*今天*/
          .today {
            color: #ffffff;
            background: #4174f7;
            border-radius: 50%;
          }
          /*延期*/
          .delay {
            color: #ffffff;
            background: #e75e58;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
/deep/.reportMod {
  .ivu-modal {
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left: -500px;
    // transform: translateX(-50%);
    width: 1000px !important;
    height: 560px;
    .ivu-modal-body {
      padding: 40px 80px;
      overflow: auto;
      table {
        width: 100%;
        border: 1px solid rgba(199, 199, 199, 1);
        tr {
          td {
            border: 1px solid rgba(199, 199, 199, 1);
            padding: 20px;
            text-align: center;
          }
        }
        thead {
          tr {
            td {
              font-size: 20px;
              font-weight: 400;
              color: #606060;
              background: rgba(15, 134, 232, 0.11);
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 20px;
              font-weight: 300;
              color: #606060;
            }
          }
        }
      }
      .modal-desc {
        padding: 18px 0 30px 0;
        border-bottom: 1px solid rgba(222, 222, 222, 1);
        h1 {
          font-size: 20px;
          font-weight: 500;
          color: rgba(52, 52, 52, 1);
        }
        div {
          font-size: 20px;
          font-weight: 500;
          color: rgba(52, 52, 52, 1);
        }
      }
    }
  }
}
.page {
  /deep/ .ivu-page {
    display: flex;
    .ivu-page-item:hover {
      border: 1px solid #dcdee2;
      display: felx;
      align-items: center;
      justify-content: center;
      a {
        color: #515a6e;
      }
    }
    .ivu-page-prev {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
