<template>
  <div id="barChart1-container"></div>
</template>

<script>
// import echarts from "echarts";
export default {
  data() {
    return {
      options: {
        // title: {
        //     text: '',
        //     textStyle: {
        //         fontSize: 16,
        //         fontWeight: 500,
        //     }
        // },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //     type: 'cross',
          //     crossStyle: {
          //         color: '#999'
          //     }
          // }
        },
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            rotate: -40,
          },
          // boundaryGap: false,

          axisLine: {
            lineStyle: {
              color: "#aaa",
              // width: 8, // 这里是为了突出显示加上的
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#ccc",
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
              // width: 8, // 这里是为了突出显示加上的
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 30,
            itemStyle: {
              normal: {
                color: "#4174F7",
              },
            },
          },
        ],
      },
      myChart: "",
    };
  },

  props: ["chartInfo"],

  components: {},

  computed: {},
  watch: {
    chartInfo() {
      if (this.chartInfo) {
        this.options.xAxis.data = this.chartInfo.trainTimeDayList;
        this.options.series[0].data = this.chartInfo.trainTimeList;
        // this.options.title.text = `本次训练时长(共计${this.chartInfo.totalTrainTime}分钟)`
        this.buildChart();
      }
    },
  },

  async mounted() {
    this.myChart = this.$echarts.init(
      document.getElementById("barChart1-container")
    );
    this.myChart.setOption(this.options);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },

  methods: {
    buildChart() {
      this.myChart.setOption(this.options);
    },
  },
};
</script>
<style lang="css" scoped>
#barChart1-container {
  width: 700px;
  height: 500px;
}
</style>
