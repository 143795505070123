<template>
    <div class="p1-container">
        <div id="brain-pie1" v-if="isBrain1"></div>
        <div id="brain-pie2" v-if="isBrain2"></div>
        <div id="brain-pie3" v-if="isBrain3"></div>
        <!-- <div class="chart-num"  v-if="isBrain1">
            <span class="num">{{currentInfo.currentBrainSore}}</span>
        </div>
        <div class="chart-num"  v-if="isBrain2">
            <span class="num">{{currentInfo.currTrainLocation}}</span>
            <span class="percent">%</span>
        </div>
        <div class="chart-num"  v-if="isBrain3">
            <span class="num">{{currentInfo.currWeekIncrease}}</span>
            <span class="percent">%</span>
        </div> -->
    </div>
</template>

<script>
// import echarts from "echarts";
// import echarts from '@js/echarts.min.js'
export default {
    data() {
        return {
            finishedPercent: 0,
            remainPercent: 0,
            options: {
                animation: false,
                series: [
                    {
                        name: "",
                        type: "pie",
                        radius: "65%",
                        silent: true,
                        label: {
                            normal: {
                                position: "inner",
                                show: false
                            }
                        },
                        color: ["#A5D3FF", "#6BB5E0"],
                        data: []
                    }
                ]
            }
        };
    },

    props: ['currentInfo', 'isBrain1', 'isBrain2', 'isBrain3'],
    components: {},

    computed: {
        // finishedPercent() {
        //     return this.currentPercent
        // },
        // remainPercent() {
        //     return (100 - this.currentPercent)
        // }
    },
    // created() {
    //     console.log('currentPercent', this.currentPercent)
    //     this.finishedPercent = this.currentPercent
    //     this.remainPercent = (100 - this.currentPercent)
    // },
    async mounted() {
        if (this.isBrain1) {
            this.myChart = this.$echarts.init(document.getElementById("brain-pie1"));
        }
        if (this.isBrain2) {
            this.myChart = this.$echarts.init(document.getElementById("brain-pie2"));
        }
        if (this.isBrain3) {
            this.myChart = this.$echarts.init(document.getElementById("brain-pie3"));
        }
        // console.log('finishedPercent data', this.options.series[0].data)
        this.myChart.setOption(this.options);
        // this.buildChart()
        window.addEventListener('resize', () => {
            this.myChart.resize()
        })
    },
    watch: {
        currentInfo: {
            handler() {
                if (this.isBrain1 && this.currentInfo) {
                    this.finishedPercent = this.currentInfo.currentBrainSore
                    this.remainPercent = (this.currentInfo.totalBrainSore - this.finishedPercent)
                }
                if (this.isBrain2 && this.currentInfo) {
                    this.finishedPercent = this.currentInfo.currTrainLocation
                    this.remainPercent = (100 - this.finishedPercent)
                }
                if (this.isBrain3 && this.currentInfo) {
                    this.finishedPercent = this.currentInfo.currWeekIncrease
                    this.remainPercent = (100 - this.finishedPercent)
                }
                // 从父组件拿到百分比的值
                this.options.series[0].data = [
                    {value: this.remainPercent},
                    {value: this.finishedPercent}
                ]
                this.buildChart()
            },
            immediate: true
        }
    },
    methods: {
        buildChart() {
            if (this.myChart) {
                this.myChart.setOption(this.options);
            }
        }
        // buildChart() {
        //     if (this.isBrain1) {
        //         this.finishedPercent = parseInt(this.currentInfo.currentBrainSore)
        //         this.remainPercent = (parseInt(this.currentInfo.totalBrainSore) - this.finishedPercent)
        //     }
        //     if (this.isBrain2) {
        //         this.finishedPercent = this.currentInfo.currTrainLocation
        //         this.remainPercent = (100 - this.finishedPercent)
        //     }
        //     if (this.isBrain3) {
        //         this.finishedPercent = this.currentInfo.currWeekIncrease
        //         this.remainPercent = (100 - this.finishedPercent)
        //     }
        //     // 从父组件拿到百分比的值
        //     this.options.series[0].data = [
        //         {value: this.remainPercent},
        //         {value: this.finishedPercent}
        //     ]
        //     this.myChart.setOption(this.options);
        // }
    }
};
</script>
<style lang="scss" scoped>
.p1-container {
    position: relative;
    #brain-pie1,
    #brain-pie2,
    #brain-pie3,
    {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
    }
    .chart-num {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        .num {
            font-size: 18px;
            font-weight: 500;
            color: #000;
        }
        .percent {
            font-size: 2px !important;
        }
    }
}
</style>
