<template>
    <div id="lineChart2-container"></div>
</template>

<script>
// import echarts from "echarts";
export default {
    data() {
        return {
            options: {
                // title: {
                //     text: "折线图堆叠"
                // },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    // orient: 'vertical',
                    // top: 'middle',
                    bottom: 10,
                    left: 'center',
                    data: ['本周指数', '上周指数'],
                    icon: 'line',
                    itemWidth: 10,
                    itemHeight: 10
                },
                // grid: {
                //     left: "3%",
                //     right: "4%",
                //     bottom: "3%",
                //     containLabel: true
                // },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [
                        "第一次",
                        "第二次",
                        "第三次",
                        "第四次",
                        "第五次",
                        "第六次",
                        "第七次"
                    ]
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            color: '#ccc',
                            type: 'dashed'
                        }
                    }
                },
                series: [
                    {
                        name: "本周指数",
                        type: "line",
                        stack: "总量",
                        data: [],
                        itemStyle: {
                            normal: {
                                color: '#24bfa9', // 折点颜色
                                lineStyle: {
                                color: '#24bfa9' // 折线颜色
                                }
                            }
                        }
                    },
                    {
                        name: "上周指数",
                        type: "line",
                        stack: "总量",
                        data: [],
                        itemStyle: {
                            normal: {
                                color: '#30a5ff', // 折点颜色
                                lineStyle: {
                                color: '#30a5ff' // 折线颜色
                                }
                            }
                        }
                    }
                ]
            }
        };
    },

    props: ['chartInfo'],

    components: {},

    computed: {},

    watch: {
        chartInfo: {
            handler() {
                if (this.chartInfo) {
                    this.options.series[0].data = this.chartInfo.currWeekBrainScore
                    this.options.series[1].data = this.chartInfo.lastWeekBrainScore
                    this.buildChart()
                }
            }
        }
    },

    async mounted() {
        this.myChart = this.$echarts.init(
            document.getElementById("lineChart2-container")
        );
        console.log("finishedPercent data", this.options.series[0].data);
        this.myChart.setOption(this.options);
        window.addEventListener("resize", () => {
            this.myChart.resize();
        });
    },

    methods: {
        buildChart() {
            if (this.myChart) {
                this.myChart.setOption(this.options)
            }
        }
    }
};
</script>
<style lang="css" scoped>
#lineChart2-container {
    width: 800px;
    height: 500px;
}
</style>
