<template>
  <div id="radar-container"></div>
</template>

<script>
// import echarts from "@js/echarts.min.js";
export default {
  name: "radarChart",
  data() {
    return {
      myChart: "",
      options: {
        title: {
          // text: "基础雷达图"
        },
        tooltip: {},
        legend: {
          data: [
            // "预算分配（Allocated Budget）",
            // "实际开销（Actual Spending）"
          ],
        },
        radar: {
          name: {
            // 全局字体样式设置
            textStyle: {
              color: "#000",
              // color: ["#12c68d", "#4174F7", "#c9c91c", "#5dc281", "#55d8b8", "#55d8b8", "#55d8b8"],
              // backgroundColor: "#999",
              borderRadius: 3,
              padding: [3, 5],
            },
          },
          // 划分为几个格子
          splitNumber: 4,
          // 每个格子的背景颜色
          splitArea: {
            areaStyle: {
              color: ["#fff", "#eee", "#fff", "#fff"],
            },
          },
          indicator: [
            // 从最顶部开始逆时针排列
            // {
            //     name: "感知力",
            //     color: "#12c68d",
            //     max: 100
            // },
            // { name: "逻辑推理", color: "#4174F7", max: 100 },
            // {
            //     name: "执行能力",
            //     color: "#ad2692",
            //     max: 100
            // },
            // { name: "记忆力", color: "#c9c91c", max: 100 },
            // { name: "反应速度", color: "#5dc281", max: 100 },
            // { name: "注意力", color: "#55d8b8", max: 100 },
            // { name: "语言", color: "#55d8b8", max: 100 }
          ],
        },
        series: [
          {
            name: "预算 vs 开销（Budget vs spending）",
            type: "radar",
            areaStyle: {},
            // areaStyle: {normal: {}},
            data: [
              {
                value: [],
                name: "脑力指数",
              },
            ],
          },
        ],
      },
    };
  },

  props: ["radarInfo"],

  components: {},

  computed: {
    radarDataList() {
      return this.radarInfo;
    },
  },
  watch: {
    radarInfo() {
      if (this.radarInfo) {
        console.log("watch radarInfo", this.radarInfo);
        this.options.radar.indicator = this.radarInfo.indicatorList;
        let data = [];
        this.radarInfo.radarValus.forEach((element) => {
          data.push(Math.ceil(element));
        });
        this.options.series[0].data[0].value = data;
        if (this.myChart) {
          this.myChart.setOption(this.options);
        }
      }
    },
  },
  async mounted() {
    this.myChart = this.$echarts.init(
      document.getElementById("radar-container")
    );
    // this.myChart.setOption(this.options);
    // this.options.radar.indicator = this.radarInfo.indicatorList
    // this.options.series[0].data[0].value = this.radarInfo.radarValus
    // this.buildChart()
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    buildChart() {
      this.myChart.setOption(this.options);
    },
  },
};
</script>
<style lang="css" scoped>
#radar-container {
  width: 500px;
  height: 500px;
  /* padding: 100px; */
}
</style>
