<template>
  <!--患者管理-->
  <Modal
    v-model="trainStatisticsModal"
    :footer-hide="true"
    @on-cancel="cancel"
    class-name="vertical-center-modal trainStaM"
  >
    <div class="stration">
      <div class="stration-tab">
        <div class="one">
          <h1>报告详情</h1>
          <img src="@/assets/img/patient/hengxian.png" alt="" />
        </div>
      </div>
      <div class="stration-main">
        <!--训练数据-->
        <div class="patientpage">
          <div class="patientpage-table">
            <div class="title">
              <div class="left"></div>
              <div class="right"></div>
            </div>
            <div class="tablebox">
              <Table
                :columns="tabColumns"
                :data="tabData"
                stripe
                border
                ref="selection"
              >
                <template slot="footer">
                  <div class="title">测评结果说明</div>
                  <p class="instruction">
                    测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明测评结果说明
                  </p>
                </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ["patientId", "trainStatisticsData"],
  components: {},
  watch: {
    trainStatisticsData: {
      handler(val) {
        if (val.patientId !== "") {
          this.searchItem.patientId = val.patientId;
          this.getData("M");
          // this.getTabData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      trainStatisticsModal: false,
      totalCount: 0, // 总数
      searchItem: {
        patientId: "",
        timeType: "M", // (D:天 M:月)
        date: "",
      },
      tabColumns: [
        {
          title: "测评结果",
          key: "trainName",
          align: "center",
        },
        {
          title: "得分",
          key: "trainTimes",
          align: "center",
        },
        {
          title: "参考值",
          key: "maxScore",
          align: "center",
        },
        {
          title: "结果",
          key: "maxScore2",
          align: "center",
        },
      ],
      tabData: [
        {
          trainName: "SCE     主诉认知状况",
          trainTimes: "5.0",
          maxScore: "7",
          maxScore2: "异常",
        },
        {
          trainName: "Mini-MMSE     总体认知能力",
          trainTimes: "5.0",
          maxScore: "7",
          maxScore2: "异常",
        },
        {
          trainName: "BABRI-EMT     情景记忆",
          trainTimes: "5.0",
          maxScore: "7",
          maxScore2: "异常",
        },
      ],
    };
  },
  methods: {
    // 打开弹框
    opentrainStatisticsModal() {
      this.searchItem.patientId = "";
      this.searchItem.timeType = "";
      this.searchItem.date = "";
      this.trainStatisticsModal = true;
    },
    // 点击关闭 回调
    cancel() {
      this.trainStatisticsModal = false;
      this.$emit("refresh");
    },
    // 点击天统计  还是  月统计
    getData(label) {
      let myData = new Date();
      let y = myData.getFullYear();
      let m = this.getZero(myData.getMonth() + 1);
      let d = this.getZero(myData.getDate());
      if (label === "D") {
        this.searchItem.date = y + "-" + m + "-" + d;
      } else if (label === "M") {
        this.searchItem.date = y + "-" + m;
      }
      this.searchItem.timeType = label;
    },
    // 时间补零
    getZero(num) {
      if (num < 10) {
        return "0" + num;
      } else {
        return num;
      }
    },
    // 获取表格数据
    getTabData() {
      let data = this.searchItem;
      this.$http
        .gets(
          "bheSystem/train/data/getList",
          this.$http.adornParams(data, false)
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            // this.tabData = res.data;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击查询
    search() {
      // this.getTabData();
    },
  },
};
</script>

<style scoped lang="scss">
/*stration*/
.stration {
  padding: 30px 0;
  background: #ffffff;
  &-tab {
    padding: 0 60px;
    display: flex;
    align-items: flex-start;
    .one {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      h1 {
        font-size: 28px;
        font-weight: 500;
        color: #4174f7;
        height: 28px;
        line-height: 28px;
      }
      img {
        margin-top: 20px;
        width: 50px;
        height: 3px;
      }
    }
    span {
      width: 1px;
      height: 23px;
      background: #cccccc;
      margin: 0 70px;
    }
  }
}
@import "@css/table.scss";
/deep/.ivu-col {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 30px;
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin-right: 20px;
    white-space: nowrap;
  }
}
.checkTime {
  border: none;
  background: #fff;
  font-size: 14px;
  color: #000;
  border: 1px solid #ededed;
  width: 80px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.selBth {
  color: #fff;
  background: #0e86e7;
  border: 1px solid #0c68b3;
}
/deep/.trainStaM {
  .ivu-modal {
    width: 90% !important;
    .ivu-modal-body {
      height: 920px !important;
      overflow: auto;
    }
  }
}
.tablebox {
  /deep/.ivu-table-footer {
    padding: 10px 45px;
    height: unset;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .instruction {
    }
  }
}
</style>
