<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <Spin fix v-if="spinShow"></Spin>
      <div class="hd">
        <Form :model="searchItem" ref="searchItems" :label-width="120">
          <!-- <Row> -->
          <!-- <i-col span="13"> -->
          <div class="formitem-box">
            <FormItem label="阶段日期：">
              <!-- <Row> -->
              <DatePicker
                type="date"
                placeholder="请选择日期"
                @on-change="changStartTime"
                size="large"
                v-model="searchItem.startDate"
                :options="optionsForDate"
                :editable="false"
                class="date"
              ></DatePicker>
              <span class="timespan">至</span>
              <DatePicker
                class="date"
                type="date"
                placeholder="请选择日期"
                @on-change="changEndTime"
                size="large"
                v-model="searchItem.endDate"
                :options="optionsForDate"
                :editable="false"
              ></DatePicker>
              <!-- </Row> -->
            </FormItem>
          </div>
          <!-- </i-col>
                        <i-col span="6"> -->
          <div class="formitem-box">
            <FormItem>
              <Button type="primary" size="large" @click="search()"
                >查询</Button
              >
              <Button
                size="large"
                @click="handleReset('searchItems')"
                style="margin-left: 20px"
                >重置</Button
              >
            </FormItem>
          </div>
          <!-- </i-col>
                    </Row> -->
        </Form>
        <div class="back-btn" @click="$router.push('/main/brainstatistics')">
          <span>返回主页</span>
          <div class="icon"></div>
        </div>
      </div>
      <div class="bt">
        <div class="table-box">
          <!-- :row-class-name="rowClassName" -->
          <Table
            :columns="tabColumns"
            :data="tabDataList"
            stripe
            border
            ref="selection"
            :loading="tableLoading"
          ></Table>
          <div class="page">
            <Page
              :total="totalCount"
              :current="searchItem.pageNumber + 1"
              :page-size="searchItem.pageSize"
              show-total
              @on-change="changPageNum"
              @on-page-size-change="changPageSize"
            />
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="isModalShow"
      title="阶段评估报告"
      footer-hide
      class-name="modal-box"
    >
      <template slot="close" @click="isModalShow = false"></template>
      <div class="info-box">
        <div class="info">
          <span class="name">编号:</span>
          <span class="val">{{ modalData.weekStageNo }}</span>
        </div>
        <div class="info">
          <span class="name">时间:</span>
          <span class="val">{{ modalData.endDate }}</span>
          <!-- <span>11:09:00</span> -->
        </div>
        <div class="info">
          <span class="name">姓名:</span>
          <span class="val">{{ modalData.patientName }}</span>
        </div>
        <div class="info">
          <span class="name">性别:</span>
          <span class="val">{{ modalData.patientSexStr }}</span>
        </div>
        <div class="info">
          <span class="name">年龄:</span>
          <span class="val">{{ modalData.patientAge }}岁</span>
        </div>
        <!-- <div class="info">
                    <span>教育年限：</span>
                    <span>{{modalData.patientAge}}</span>
                </div> -->
      </div>
      <div class="chart-box">
        <div class="hd">
          <span class="title">脑力指数：</span>
        </div>
        <div class="bt">
          <line-chart2 :chartInfo="modalData"></line-chart2>
        </div>
      </div>
      <div class="chart-box special-box">
        <div class="hd">
          <span class="title">专项认知：</span>
        </div>
        <div class="bt">
          <bar-chart2 :chartInfo="modalData"></bar-chart2>
        </div>
      </div>
      <div class="chart-box pie-box">
        <div class="hd">
          <span class="title">训练次数：</span>
        </div>
        <div class="bt">
          <pie-stage3 :chartInfo="modalData"></pie-stage3>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
import lineChart2 from "@v/patientend/chartItems/stageReport/lineChart2";
import barChart2 from "@v/patientend/chartItems/stageReport/barChart2";
import pieStage3 from "@v/patientend/chartItems/stageReport/pieChart3";
export default {
  data() {
    return {
      optionsForDate: {
        disabledDate(date) {
          return (
            (date && date.valueOf() >= Date.now()) ||
            date.valueOf() < new Date(2019, 0, 1)
          );
        },
      },
      spinShow: true,
      modalData: "",
      tabDataList: [],
      isModalShow: false,
      tableLoading: false,
      delePData: {
        patientName: "",
        patientId: "",
        delePatientModal: false,
      },
      doctorId: "",
      searchItem: {
        patientId: this.$store.state.patientInfo.patientId,
        startDate: "",
        endDate: "",
        pageNumber: 0,
        pageSize: 10,
      },
      totalCount: 0, // 总数
      tabColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              {},
              params.index +
                1 +
                this.searchItem.pageNumber * this.searchItem.pageSize
            );
          },
        },
        {
          title: "姓名",
          key: "patientName",
          align: "center",
          // width: 160
        },
        {
          title: "阶段日期",
          key: "endDate",
          // width: 250,
          align: "center",
        },
        {
          title: "脑力指数",
          key: "brainScore",
          // width: 120,
          align: "center",
          render: (h, params) => {
            const flag = params.row.brainScoreUpFlag;
            if (flag === 1) {
              return h("div", [
                h("span", params.row.brainScore),
                h("Icon", {
                  props: {
                    type: "md-arrow-round-up",
                    size: 18,
                    color: "#08b300",
                  },
                }),
              ]);
            } else if (flag === 0) {
              return h("div", [
                h("span", params.row.brainScore),
                h("Icon", {
                  props: {
                    type: "md-arrow-round-down",
                    size: 18,
                    color: "#ff2600",
                  },
                }),
              ]);
            } else {
              return h("div", [h("span", params.row.brainScore)]);
            }
          },
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    color: "#0E86E7",
                    cursor: "pointer",
                  },
                  on: {
                    click: () => {
                      this.opModal(params.row);
                    },
                  },
                },
                "详情"
              ),
            ]);
          },
        },
      ],
    };
  },

  components: {
    // tabarhead,
    lineChart2,
    barChart2,
    // pieChart,
    pieStage3,
  },

  created() {
    this.getInitData();
  },

  mounted() {
    setTimeout(() => {
      this.spinShow = false;
    }, 300);
  },

  computed: {},

  watch: {
    // isModalShow() {
    //     if (this.isModalShow) {
    //         this.getModalData()
    //     }
    // }
  },

  methods: {
    async getInitData() {
      console.log("调用了getInitData");
      try {
        const res = await this.$http.post(
          "patient/plan/info/getTrainStageReportList",
          this.$http.adornData(this.searchItem, false, "json"),
          this.$http.jsonContent()
        );
        if (res.code === 1) {
          // tabDataList不能直接用，要根据brainScoreUpFlag
          // this.tabDataList = res.data.map(item => {
          //     // brainScoreUpFlag为1的情况
          //     if (item.brainScoreUpFlag) {
          //         item.brainScore = item.brainScore + ' ↑'
          //     } else {
          //         item.brainScore = item.brainScore + ' ↓'
          //     }
          // })
          this.tabDataList = res.data;
          console.log("getTrainStageReportList", res);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 开始时间选中
    changStartTime(value) {
      this.searchItem.startDate = value;
    },
    // 结束时间选中
    changEndTime(value) {
      if (this.searchItem.startDate > value) {
        this.searchItem.endDate = "";
        return this.$Message.error("开始时间不能大于结束日期");
      }
      this.searchItem.endDate = value;
    },
    // 分页改变页数
    changPageNum(num) {
      this.searchItem.pageNumber = num - 1;
      this.getInitData();
    },
    // 分页改变条数
    changPageSize(size) {
      this.searchItem.pageSize = size;
      this.getInitData();
    },
    // 点击查询
    search() {
      this.searchItem.pageNumber = 0;
      this.getInitData();
    },
    // 点击重置
    handleReset(name) {
      this.$refs[name].resetFields();
      this.searchItem.startDate = "";
      this.searchItem.endDate = "";
    },
    // 打开详情图标modal
    async opModal(rowData) {
      this.isModalShow = true;
      try {
        const res = this.$http.post(
          "patient/plan/info/getTrainStageReportDtlInfo",
          this.$http.adornData(this.searchItem, false, "json"),
          this.$http.jsonContent()
        );
        if (res.code === 1) {
          this.modalData = res.data;
          console.log("getTrainStageReportDtlInfo", res);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
      // console.log('rowData', rowData)
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: 100% !important;
  background-color: #eaf2f8;
  .card {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    // height: 794px;
    height: 770px;
    background: #fff;
    border: 1px solid #d2d2d2;
    box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
    border-radius: 40px;
    overflow: hidden;
    // line-height: 1;
    .hd {
      display: flex;
      align-items: center;
      height: 120px;
      padding: 0 40px;
      border-radius: 20px 20px 0 0;
      /deep/.ivu-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .formitem-box {
          &:first-child {
          }
          .date {
            width: 40%;
          }
          .ivu-form-item {
            .ivu-form-item-content {
              display: flex;
            }
          }
        }
        .ivu-form-item {
          margin-bottom: unset;
          .ivu-form-item-label {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
          }
          .timespan {
            margin: 0 19px;
            font-size: 20px;
            color: #5f5f5f;
          }
        }
      }
      .back-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 175px;
        height: 48px;
        margin-left: 200px;
        background: #e7f2fd;
        border-radius: 24px;
        cursor: pointer;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #4174f7;
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-left: 8px;
          background: url("~@/assets/img/patientend/i-backbtn.png");
          background-size: cover;
        }
      }
    }
    .bt {
      padding: 0 40px;
      .table-box {
        margin-top: 20px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        }
        /deep/.ivu-table {
          font-size: 20px;
          font-weight: 400;
          color: #535353;
          thead {
            tr {
              th {
                font-weight: 500;
                color: #5f5f5f;
                background: rgba(14, 134, 231, 0.11) !important;
                .ivu-table-cell {
                  overflow: visible !important;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                .ivu-table-cell {
                  overflow: visible !important;
                }
              }
            }
          }
        }
        /deep/.page {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0;
        }
      }
    }
  }
}
/deep/.modal-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    position: relative;
    top: 0;
    width: 1440px !important;
    // height: 780px !important;
    margin: unset;
    // margin-top: 50px; todo
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    // overflow-y: scroll;
    .ivu-modal-header {
      // position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1440px;
      height: 84px !important;
      background: #e5f2fc;
      border-radius: 20px 20px 0 0;
      .ivu-modal-header-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 30px;
        font-weight: 500;
        color: #333333;
      }
    }
    .ivu-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 696px;
      padding: unset;
      padding-top: 84px;
      padding-bottom: 100px;
      overflow: auto;
      .chart-box {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        width: 1080px;
        // height: 420px;
        margin-top: 30px;
        background: #ffffff;
        border: 1px solid #cccccc;
        // overflow: hidden;
        .hd {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 1080px;
          height: 48px;
          border-left: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          background: #e0efff;
          .title {
            margin-left: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
          }
        }
        .bt {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .special-box {
        height: unset !important;
      }
      .pie-box {
        height: unset !important;
      }
    }
    .info-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      // margin-top: 60px;
      line-height: 1;
      .info {
        .name {
          margin-right: 14px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
        .val {
          margin-right: 40px;
          font-size: 20px;
          font-weight: 400;
          color: #5f5f5f;
          opacity: 0.7;
        }
      }
    }
  }
  .ivu-modal-content {
    position: relative;
    // height: 780px !important;
    .ivu-modal-close {
      position: absolute;
      top: 30px;
      right: 40px;
      display: block;
      width: 24px;
      height: 24px;
      background: url("~@/assets/img/patientend/xclose.png");
      background-size: cover;
      ::before {
        display: none;
      }
    }
  }
}
.page {
  /deep/ .ivu-page {
    .ivu-page-item:hover {
      border: 1px solid #dcdee2;
      a {
        color: #515a6e;
      }
    }
  }
}
</style>
