<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <Spin fix v-if="spinShow"></Spin>
      <div class="head">
        <div class="title">报告详情</div>
        <div class="close-btn" @click="backToHome"></div>
      </div>
      <div class="middle" v-if="modal1">
        <div class="model_top">
          <div class="model_top_item">
            <div class="model_top_item_label">姓名：</div>
            <div class="model_top_item_value">{{ scaleReport.name }}</div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">性别：</div>
            <div class="model_top_item_value">{{ scaleReport.gender }}</div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">年龄：</div>
            <div class="model_top_item_value">{{ scaleReport.age }}</div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">教育年限：</div>
            <div class="model_top_item_value">{{ scaleReport.eduTotal }}</div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">编号：</div>
            <div class="model_top_item_value">{{ scaleReport.reportNo }}</div>
          </div>
        </div>
        <div class="table-box">
          <table>
            <thead>
              <tr>
                <td>测评内容</td>
                <td>得分</td>
                <td>参考值</td>
                <td>结果</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in scaleReport.detailContents"
                :key="index"
              >
                <td>{{ item.projectName }} {{ item.content }}</td>
                <td>{{ item.score }}</td>
                <td>{{ item.reference }}</td>
                <td :class="item.result === '异常' ? '' : ''">
                  {{ item.result }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-desc">
          <h1>测评结果说明：</h1>
          <div
            v-html="item.content"
            v-for="(item, index) in scaleReport.resultContents"
            :key="index"
          ></div>
        </div>
      </div>
      <div class="middle middle_modal2" v-if="modal2">
        <reportFunctionDetails
          :scaleReport="scaleReport"
        ></reportFunctionDetails>
      </div>
      <div class="middle middle_modal2" v-if="modal3">
        <reportSeniorDetails :scaleReport="scaleReport"></reportSeniorDetails>
      </div>
      <div class="middle middle_modal2" v-if="modal4">
        <reportRourthDetails :scaleReport="scaleReport"></reportRourthDetails>
      </div>
    </div>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
import reportFunctionDetails from "./reportFunctionDetails.vue";
import reportSeniorDetails from "./reportSeniorDetails.vue";
import reportRourthDetails from "./reportRourthDetails";

export default {
  name: "reportDetails",
  components: {
    // tabarhead,
    reportFunctionDetails,
    reportSeniorDetails,
    reportRourthDetails,
  },
  data() {
    return {
      spinShow: true,
      scaleReport: "",
      groupId: "",
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
    };
  },
  watch: {
    groupId: function () {
      console.log(this.groupId);
    },
  },
  computed: {
    // content() {
    //     if (this.scaleReport) {
    //         return this.scaleReport.content;
    //     }
    //     return ''
    // },
  },
  mounted() {
    this.masterId = this.$route.query.masterId;
    this.groupId = this.$route.query.groupId;
    this.getInitData();
    setTimeout(() => {
      this.spinShow = false;
    }, 400);
  },

  methods: {
    getInitData() {
      this.modal2 = false;
      this.modal3 = false;
      this.modal1 = false;
      this.modal4 = false;
      this.scaleReport = "";
      // 获取报告详情
      let data1 = {
        masterId: this.masterId,
        // masterId: "00493e49736e4e4989b50310aeb9c3f5",
        utype: "D",
      };
      this.$http
        .post(
          "report/getResult",
          this.$http.adornData(data1, false, "json"),
          this.$http.jsonContent()
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            // todo
            this.scaleReport = res.data;
            console.log("scaleReport", this.scaleReport);
            if (this.groupId === "74aee47313f64638bf765bc8ae3c8897") {
              this.modal2 = true;
            } else if (this.groupId === "1c212cdb5810489890d306575805254d") {
              this.modal3 = true;
            } else if (this.groupId === "b311d0f270ae488f9d6d404bcb2cc24e") {
              console.log(this.groupId);
              this.modal4 = true;
            } else {
              this.modal1 = true;
            }
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backToHome() {
      this.$router.push("/main/patientReport");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@css/mixin.scss";

.container {
  position: relative;
  height: 100%;
  background-color: #eaf2f8;

  .card {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    height: 794px;
    height: 770px;
    background: #fff;
    border: 1px solid #d2d2d2;
    box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
    border-radius: 40px;
    line-height: 1;

    .ivu-spin {
      border-radius: 40px 40px 0 0;
    }

    .head {
      position: relative;
      height: 77px;
      padding-top: 26px;
      margin-bottom: 40px;
      border-radius: 40px 40px 0 0;

      &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -3px);
        content: "";
        width: 48px;
        height: 3px;
        margin-bottom: 60px;
        background: #4174f7;
        border-radius: 2px;
      }

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #4174f7;
      }

      .close-btn {
        position: absolute;
        top: 35px;
        right: 53px;
        width: 40px;
        height: 40px;
        background: url("~@/assets/img/patientend/i-close.png") no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
      }
    }

    .middle {
      padding: 0 211px;
      // height: 500px;
      // overflow-y: auto;
      table {
        width: 100%;
        tr {
          td {
            height: 70px;
            border: 1px solid rgba(199, 199, 199, 1);
            padding: 20px;
            text-align: center;
            font-size: 22px;
            font-weight: 400;
            color: #5f5f5f;
          }
        }

        thead {
          tr {
            background: #e5f2fc;

            td {
              height: 70px;
              font-size: 20px;
              font-weight: 400;
              color: #5f5f5f;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 20px;
              font-weight: 300;
              color: #606060;
            }

            .reds {
              // color: rgba(255, 3, 27, 1) !important;
              color: "";
            }
          }
        }
      }

      .modal-desc {
        padding: 18px 0 30px 0;
        // border-bottom: 1px solid rgba(222, 222, 222, 1);
        h1 {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 400;
          color: #5f5f5f;
        }

        div {
          font-size: 20px;
          font-weight: 400;
          color: #5f5f5f;
          opacity: 0.7;
        }
      }
    }
  }
}
.middle_modal2 {
  height: 78%;
  overflow-y: auto;
}
.model_top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;

  .model_top_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .model_top_item_label {
    color: #999999;
    font-size: 20px;
  }

  .model_top_item_value {
    color: #333333;
    font-size: 20px;
  }
}
.table-box {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 40px;
}
</style>
