<template>
  <div class="container">
    <div class="left">
      <div class="head">
        <div class="desc-box">
          <div class="score-box">
            <span class="words">本次得分：</span>
            <span class="num">{{ chartInfo.trainScore }}</span>
          </div>
          <div class="desc">您的成绩在人群中的位置</div>
        </div>
      </div>
      <div class="middle">
        <div class="chart-box">
          <line-charts
            :chartData="chardata"
            :arrDataList="arrDataList"
          ></line-charts>
        </div>
        <div class="icon-box">
          <div class="icon-1">
            <div class="icon c1"></div>
            <span>初级</span>
          </div>
          <div class="icon-1">
            <div class="icon c2"></div>
            <span>中级</span>
          </div>
          <div class="icon-1">
            <div class="icon c3"></div>
            <span>高级</span>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="chartInfo.pageRank < 16">
        <span class="desc">您的成绩超过了</span>
        <span class="num">{{ parseInt(chartInfo.pageRank) }}</span>
        <span class="percent">%</span>
        <span class="desc">的用户，要加倍努力哦！</span>
      </div>
      <div
        class="bottom"
        v-if="chartInfo.pageRank >= 16 && chartInfo.pageRank < 84"
      >
        <span class="desc">您的成绩超过了</span>
        <span class="num">{{ parseInt(chartInfo.pageRank) }}</span>
        <span class="percent">%</span>
        <span class="desc">的用户，继续加油！</span>
      </div>
      <div class="bottom" v-if="chartInfo.pageRank >= 84">
        <span class="desc">您的成绩超过了</span>
        <span class="num">{{ parseInt(chartInfo.pageRank) }}</span>
        <span class="percent">%</span>
        <span class="desc">的用户，太棒了！</span>
      </div>
    </div>
    <div class="right">
      <div class="hd">
        <div class="title-box">结果说明</div>
      </div>
      <div class="bt">
        <p class="desc1">
          {{ chartInfo.comment }}
        </p>
        <!-- <p class="desc2">
                   您可以继续进行视空间领域的相关训练，进一步提升您的空间定位能力。
               </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import lineCharts from "@v/patientend/chartItems/trainResult/lineLocation";
export default {
  //   name: "trainResult",
  data() {
    return {
      //   chartInfo: "",
      //   schedId: '',
      //   trainId: ''
      chardata: "",
      arrDataList: "",
    };
  },

  //   props: ["trainInfo"],
  props: ["chartInfo", "arrData"],

  components: {
    lineCharts,
  },
  mounted() {
    this.chardata = this.chartInfo;
    this.arrDataList = this.arrData;
    console.log(this.arrDataList, this.arrData);
  },

  //   computed: {
  //     schedId() {
  //       return this.trainInfo.schedId;
  //     },
  //     trainId() {
  //       return this.trainInfo.trainId;
  //     },
  //   },

  //   watch: {
  //     trainInfo: {
  //       handler() {
  //         console.log("watch trainInfo", this.trainInfo);
  //         if (this.trainInfo) {
  //           this.schedId = this.trainInfo.schedId;
  //           this.trainId = this.trainInfo.trainId;
  //           //    this.getInitData()
  //         }
  //       },
  //     },
  //   },

  //   mounted() {
  //     this.getInitData();
  //   },

  //   methods: {
  //     async getInitData() {
  //       try {
  //         const res = await this.$http.get(
  //           `/train/data/getTrainLocation?schedId=${this.schedId}&trainId=${this.trainId}`
  //           // '/train/data/getTrainLocation?schedId=40a1d7f201884ad5ac2963acc9b5316b&trainId=14'
  //         );
  //         if (res.code === 1) {
  //           this.chartInfo = res.data;
  //           console.log("res location", res);
  //         } else {
  //           this.$Message.error(res.msg);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     },
  //   },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  .left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 710px;
    // height: 410px;
    height: 500px;
    margin-right: 20px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    .head {
      padding-top: 20px;
      // 底边框盒子
      .desc-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        width: 590px;
        // padding: 21px 59px 0;
        border-bottom: 1px solid rgba(95, 95, 95, 0.5);
        background: transparent;
        .score-box {
          display: flex;
          align-items: center;
          .words {
            font-size: 24px;
            font-weight: 500;
            color: #000000;
          }
          .num {
            font-size: 24px;
            font-weight: 500;
            color: #4174f7;
          }
        }
        .desc {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          opacity: 0.7;
        }
      }
    }
    .middle {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 36px;
      .chart-box {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -66%);
        // width: 600px;
        // height: 350px;
        background: transparent;
      }
      .icon-box {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-40%);
        display: flex;
        flex-direction: row;
        z-index: 999;
        .icon-1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 20px;
          font-size: 18px;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
      .c1 {
        background: #fcf1ce;
      }
      .c2 {
        background: #def5ec;
      }
      .c3 {
        background: #d0e7f9;
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 46px;
      width: 100%;
      padding-bottom: 12px;
      background: #e0efff;
      border-radius: 5px;
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        opacity: 0.7;
      }
      .num {
        margin-left: 11px;
        margin-right: 5px;
        font-size: 28px;
        font-weight: 500;
        line-height: 20px;
        color: #4174f7;
      }
      .percent {
        margin-right: 11px;
        font-size: 20px;
        font-weight: 500;
        color: #4174f7;
        opacity: 0.7;
      }
    }
  }
  .right {
    width: 710px;
    // height: 410px;
    height: 500px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    .hd {
      display: flex;
      align-items: center;
      padding: 20px 60px 0;
      .title-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 62px;
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        border-bottom: 1px solid rgba(95, 95, 95, 0.5);
      }
    }
    .bt {
      padding-top: 17px;
      padding-left: 60px;
      padding-right: 60px;
      height: 417px;
      background: url("~@/assets/img/patientend/train-result.png") no-repeat;
      background-size: 459px 200px;
      background-position: left bottom;
      .desc1,
      .desc2 {
        font-size: 20px;
        font-weight: 400;
        color: #5f5f5f;
        line-height: 32px;
      }
      .desc2 {
        margin-top: 17px;
      }
    }
  }
}
</style>
