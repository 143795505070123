<template>
    <div id="main"></div>
</template>

<script>
// import echarts from "echarts";
export default {
    data() {
        return {
            myChart: "",
            options: {
                color: ["#cde2f0", "#6dbff6"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                legend: {
                    // orient: 'vertical',
                    // top: 'middle',
                    bottom: 10,
                    left: 'center',
                    data: ['本周', '上周'],
                    icon: 'rect',
                    itemWidth: 10,
                    itemHeight: 10
                },
                xAxis: [
                    {
                        type: "category",
                        axisTick: { show: false },
                        data: []
                    }
                ],
                yAxis: [
                    {
                        type: "value",
                        splitLine: {
                            lineStyle: {
                                color: '#ccc',
                                type: 'dashed'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: "上周",
                        type: "bar",
                        barGap: 0,
                        label: "",
                        data: []
                    },
                    {
                        name: "本周",
                        type: "bar",
                        label: "",
                        data: []
                    }
                ]
            }
        };
    },

    props: ["chartInfo"],

    components: {},

    computed: {},

    watch: {
        chartInfo: {
            handler() {
                if (this.chartInfo) {
                    this.options.xAxis[0].data = this.chartInfo.lastWeekSpecialString
                    this.options.series[0].data = this.chartInfo.lastWeekSpecialScore
                    this.options.series[1].data = this.chartInfo.currWeekSpecialScore
                    this.buildChart()
                }
            }
        }
    },

    async mounted() {
        this.myChart = this.$echarts.init(document.getElementById("main"));
        this.myChart.setOption(this.options);
        this.buildChart();
        window.addEventListener("resize", () => {
            this.myChart.resize();
        });
    },
    methods: {
        buildChart() {
            this.myChart.setOption(this.options);
        }
    }
};
</script>
<style lang="css" scoped>
#main {
    width: 800px;
    height: 500px;
}
</style>
