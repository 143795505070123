<template>
    <div class="p-container">
        <div id="stage3"></div>
    </div>
</template>

<script>
// import echarts from "echarts";
// import echarts from '@js/echarts.min.js'
export default {
    data() {
        return {
            finishedPercent: 0,
            remainPercent: 0,
            options: {
                // title: {
                //     text: "某站点用户访问来源",
                //     subtext: "纯属虚构",
                //     left: "center"
                // },
                animation: false,
                tooltip: {
                    trigger: "item",
                    // formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                // legend: {
                //     orient: "vertical",
                //     left: "left",
                //     data: []
                // },
                legend: {
                    // orient: 'vertical',
                    // top: 'middle',
                    bottom: 10,
                    left: 'center',
                    data: [],
                    icon: 'circle',
                    itemWidth: 10,
                    itemHeight: 10
                },
                // color: ['#6b9bc2', '#6dbff6', '#68bad4', '#67d3f2', '#05d6d1', '#66ceb8', '#66e7cc'], // 蓝绿色
                color: ['#66a4ce', '#76add1', '#88b9d9', '#9dc5e0', '#b4d3e8', '#cde2f0', '#ddebf3'], // 蓝色由深到浅
                series: [
                    {
                        name: "训练次数",
                        type: "pie",
                        // radius: "70%",
                        radius: ['30%', '70%'],
                        center: ["50%", "40%"],
                        data: [
                            // { value: 335, name: "直接访问" },
                            // { value: 310, name: "邮件营销" },
                            // { value: 234, name: "联盟广告" },
                            // { value: 135, name: "视频广告" },
                            // { value: 1548, name: "搜索引擎" }
                        ],
                        label: {
                            normal: {
                            position: 'inner',
                            // formatter: "{b}: {c} ({d}%)"
                            color: '#000'
                            }
                        },
                        labelLine: {
                            normal: {
                            show: false
                            }
                        }
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 10,
                        //         shadowOffsetX: 0,
                        //         shadowColor: "rgba(0, 0, 0, 0.5)"
                        //     }
                        // }
                    }
                ]
            }
        };
    },

    props: ["chartInfo"],
    components: {},

    computed: {
        // finishedPercent() {
        //     return this.currentPercent
        // },
        // remainPercent() {
        //     return (100 - this.currentPercent)
        // }
    },
    // created() {
    //     console.log('currentPercent', this.currentPercent)
    //     this.finishedPercent = this.currentPercent
    //     this.remainPercent = (100 - this.currentPercent)
    // },
    async mounted() {
        this.myChart = this.$echarts.init(document.getElementById("stage3"));
        // console.log('finishedPercent data', this.options.series[0].data)
        this.myChart.setOption(this.options);
        // this.buildChart()
        window.addEventListener("resize", () => {
            this.myChart.resize();
        });
    },
    watch: {
        chartInfo: {
            handler() {
                // trainTimesName trainTimesScore
                if (this.chartInfo) {
                    const nameList = this.chartInfo.trainTimesName
                    const scoreList = this.chartInfo.trainTimesScore
                    let sum = 0
                    scoreList.forEach(item => {
                        sum += item
                    })
                    // console.log('sum', sum)
                    // let percent0 = (scoreList[0] / sum).toFixed(2) * 100
                    // let percent1 = (scoreList[1] / sum).toFixed(2) * 100
                    // let percent2 = (scoreList[2] / sum).toFixed(2) * 100
                    // let percent3 = (scoreList[3] / sum).toFixed(2) * 100
                    // let percent4 = (scoreList[4] / sum).toFixed(2) * 100
                    // let percent5 = (scoreList[5] / sum).toFixed(2) * 100
                    // let percent6 = (scoreList[6] / sum).toFixed(2) * 100
                    // console.log('pie data', nameList, scoreList)
                    this.options.legend.data = nameList
                    this.options.series[0].data = [
                        { value: scoreList[0], name: nameList[0], label: {formatter: `${scoreList[0]}次`} },
                        { value: scoreList[1], name: nameList[1], label: {formatter: `${scoreList[1]}次`} },
                        { value: scoreList[2], name: nameList[2], label: {formatter: `${scoreList[2]}次`} },
                        { value: scoreList[3], name: nameList[3], label: {formatter: `${scoreList[3]}次`} },
                        { value: scoreList[4], name: nameList[4], label: {formatter: `${scoreList[4]}次`} },
                        { value: scoreList[5], name: nameList[5], label: {formatter: `${scoreList[5]}次`} },
                        { value: scoreList[6], name: nameList[6], label: {formatter: `${scoreList[6]}次`} },
                    ]
                }
                this.buildChart();
            },
            immediate: true
        }
    },
    methods: {
        buildChart() {
            if (this.myChart) {
                this.myChart.setOption(this.options);
            }
        }
        // buildChart() {
        //     if (this.isBrain1) {
        //         this.finishedPercent = parseInt(this.currentInfo.currentBrainSore)
        //         this.remainPercent = (parseInt(this.currentInfo.totalBrainSore) - this.finishedPercent)
        //     }
        //     if (this.isBrain2) {
        //         this.finishedPercent = this.currentInfo.currTrainLocation
        //         this.remainPercent = (100 - this.finishedPercent)
        //     }
        //     if (this.isBrain3) {
        //         this.finishedPercent = this.currentInfo.currWeekIncrease
        //         this.remainPercent = (100 - this.finishedPercent)
        //     }
        //     // 从父组件拿到百分比的值
        //     this.options.series[0].data = [
        //         {value: this.remainPercent},
        //         {value: this.finishedPercent}
        //     ]
        //     this.myChart.setOption(this.options);
        // }
    }
};
</script>
<style lang="scss" scoped>
.p-container {
    position: relative;
    #stage3 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 800px;
        height: 500px;
    }
}
</style>
