<template>
  <div class="container">
    <!-- <tabarhead></tabarhead> -->
    <div class="card">
      <div class="card_head" v-if="patientTrainInfo.trainUrl !== ''">
        <div>
          <img :src="imagedata !== '' ? imagedata : imagedatas" alt="" />
          <Poptip
            trigger="hover"
            :content="patientTrainInfo.patientName"
            placement="bottom"
          >
            <span>{{ handleLongName }}</span>
          </Poptip>
          <span class="asv"> {{ patientTrainInfo.patientSexStr }}</span>
          <span> {{ patientTrainInfo.patientAge }}岁 </span>
        </div>
        <div>
          <p>
            <span>{{ patientTrainInfo.trainInfoDesc }}</span>
            <span v-if="patientTrainInfo.todayFinishProcess">{{
              patientTrainInfo.todayFinishProcess === 0
                ? patientTrainInfo.trainTypeName
                : patientTrainInfo.trainTypeName + "训练"
            }}</span>
          </p>
          <p>
            <span>当前训练时长</span>
            <span>{{ patientTrainInfo.currentDuration }}</span>
          </p>
          <p>
            <span>剩余训练时长</span>
            <span>{{ patientTrainInfo.remainsDuration }}</span>
          </p>
          <p>
            <span>今日完成度</span>
            <span>{{ patientTrainInfo.todayFinishProcess }}%</span>
          </p>
        </div>
        <div>
          <p @click="backToHome">
            <img src="../../assets/img/off.png" alt="" /> 结束训练
          </p>
        </div>
      </div>
      <div class="card_content">
        <!-- 如果是训练则显示介绍页 视频的话直接显示视频页 -->
        <div class="video-wrapper" v-if="isVideoShow">
          <div class="inner-box">
            <div class="video-box" v-if="this.videoUrl">
              <!-- @callback="handleVideo" -->
              <train-video
                :trainScale="patientTrainInfo"
                :videoUrl="videoUrl"
                :againTrainFlg="againTrainFlg"
                :trainInfoDesc="patientTrainInfo.trainInfoDesc"
                :trainIntroduce="patientTrainInfo.trainIntroduce"
              ></train-video>
            </div>
            <div class="video-info">
              <div class="title">
                {{ patientTrainInfo.trainInfoDesc }}
              </div>
              <p class="desc">
                {{ patientTrainInfo.trainIntroduce }}
              </p>
            </div>
          </div>
        </div>
        <!-- 如果是训练则显示详情介绍页 TODO-->
        <div class="middle" v-if="isDetailsShow">
          <div class="name-times">
            <div class="zhanwei"></div>
            <div class="name-box">
              <span>{{ patientTrainInfo.trainInfoDesc }}</span>
              <span>{{ patientTrainInfo.trainTypeName }}训练</span>
            </div>
            <!-- 如果是单个训练就不显示1/3 -->
            <div class="times-box">
              <div class="times-box1" v-if="!canOpenFlag">
                <div class="word">训练次数</div>
                <div class="times" v-if="patientTrainInfo.currentTrainCount">
                  <div class="finish-num">
                    第{{ patientTrainInfo.currentTrainCount }}次
                  </div>
                  <span class="fen">/</span>
                  <div class="remain-num">
                    共{{ patientTrainInfo.currentTrainTotalCount }}次
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <span class="title">训练介绍</span>
            <span class="desc">{{
              introInfos.trainDescribe ? introInfos.trainDescribe : ""
            }}</span>
          </div>
          <div class="imgs">
            <div
              class="img-box"
              v-for="(item, index) in introInfos.imgs"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
        <!-- 如果是进入训练页面则显示iframe -->
        <div
          class="frame-box"
          v-if="isFrameShow"
          :class="isFrameShow ? 'boxframe' : ''"
        >
          <iframe
            id="iframe"
            :src="frameSrc"
            ref="iframe"
            style="margin-top: 80px"
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
            scrolling="no"
            frameborder="0"
            :class="isFrameShow ? 'fullScreen' : ''"
          ></iframe>
        </div>
        <!-- 底部继续按钮 绝对定位定在底部 -->
        <div class="bottom">
          <div
            class="btn-box"
            @click="clickContinue"
            ref="trainInfoBtn"
            v-if="isBtnShow"
          >
            <span class="btn-word">{{ btnText }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="head" v-if="patientTrainInfo"> -->
      <!-- <div class="head-center"> -->
      <!-- <div class="patient">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">就诊人：</span>
            <Poptip
              trigger="hover"
              :content="patientTrainInfo.patientName"
              placement="bottom"
            >
              <span class="val">{{ handleLongName }}</span>
            </Poptip>
            <span class="name gender">{{
              patientTrainInfo.patientSexStr
            }}</span>
            <span class="name age">{{ patientTrainInfo.patientAge }}岁</span>
          </div>
        </div>
        <div class="current-train">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">{{ patientTrainInfo.trainInfoDesc }}：</span>
            <span class="val">{{ patientTrainInfo.trainTypeName }}</span>
          </div>
        </div>
        <div class="current-duration">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">当前训练时长：</span>
            <span class="val">{{ patientTrainInfo.currentDuration }}</span>
          </div>
        </div>
        <div class="remain-time">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">剩余训练时长：</span>
            <span class="val">{{ patientTrainInfo.remainsDuration }}</span>
          </div>
        </div>
        <div class="today-finished">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">今日完成度：</span>
            <span class="val">{{ patientTrainInfo.todayFinishProcess }}</span>
            <span class="percent">%</span>
          </div>
        </div>
        <div class="close-btn" @click="backToHome"></div>
      </div> -->
      <!-- 如果是训练则显示介绍页 视频的话直接显示视频页 -->
      <!-- <div class="video-wrapper" v-if="isVideoShow">
        <div class="inner-box">
          <div class="video-box" v-if="this.videoUrl">
            <train-video
              @callback="handleVideo"
              :trainScale="patientTrainInfo"
              :videoUrl="videoUrl"
              :againTrainFlg="againTrainFlg"
            ></train-video>
          </div>
          <div class="video-info">
            <div class="title">
              {{ patientTrainInfo.trainInfoDesc }}
            </div>
            <p class="desc">
              {{ patientTrainInfo.trainIntroduce }}
            </p>
          </div>
        </div>
      </div> -->
      <!-- 如果是训练则显示详情介绍页 TODO-->
      <!-- <div class="middle" v-if="isDetailsShow">
        <div class="name-times">
          <div class="name-box">
            <div class="icon1"></div>
            <div class="name">{{ introInfos.typeName }}训练</div>
          </div> -->
      <!-- 分割线，单个训练时不显示 -->
      <!-- <div class="line" v-if="!canOpenFlag"></div> -->
      <!-- 如果是单个训练就不显示1/3 -->
      <!-- <div class="times-box" v-if="!canOpenFlag">
            <div class="word">训练次数：</div>
            <div class="times" v-if="patientTrainInfo.currentTrainCount">
              <div class="finish-num">
                {{ patientTrainInfo.currentTrainCount }}
              </div>
              <div class="icon2"></div>
              <div class="remain-num">
                {{ patientTrainInfo.currentTrainTotalCount }}
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <span class="title">训练介绍</span>
          <span class="desc">{{
            introInfos.trainDescribe ? introInfos.trainDescribe : ""
          }}</span>
        </div>
        <div class="imgs">
          <div
            class="img-box"
            v-for="(item, index) in introInfos.imgs"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
      </div> -->
      <!-- 如果是进入训练页面则显示iframe -->
      <!-- <div
        class="frame-box"
        v-if="isFrameShow"
        :class="isFrameShow ? 'boxframe' : ''"
      >
        <iframe
          id="iframe"
          :src="frameSrc"
          ref="iframe"
          style="margin-top: 80px"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
          scrolling="no"
          frameborder="0"
          :class="isFrameShow ? 'fullScreen' : ''"
        ></iframe>
      </div> -->
      <!-- 训练结束后显示训练结果 todo-->
      <train-result
        v-if="isResultShow"
        :chartInfo="chartInfo"
        :arrData="arrData"
      ></train-result>
      <!-- 结束当天训练时显示雷达图 -->
      <radar-charts v-if="isRadarShow"></radar-charts>
    </div>
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
import trainVideo from "./items/trainVideo3";
import trainResult from "./items/trainResult";
import { Debounce } from "@js/debounce.js";
export default {
  name: "TrainInfo",
  data() {
    return {
      isDetailsShow: false, // 控制训练详情的显示
      isVideoShow: false, // 控制视频页面的显示
      isFrameShow: false, // 控制frame窗口的显示
      isResultShow: false, // 控制结果页的显示
      isRadarShow: false, // 控制是否显示雷达图
      frameSrc: "",
      // playInfo: "", // 播放子组件传递过来的播放信息
      patientTrainInfo: {},
      introInfos: {}, // 用于保存详情页的数据,
      videoUrl: "",
      iframeMsgCount: 0, // todo临时变量 只在第二次时操作
      testIdx: 1, // todo
      patientPlanId2: "", // 单个训练传过来的参数
      schedDtlId: "", // 单个训练传过来的参数
      // browserInfo: "" // 浏览器信息
      currentPlanIndex: "", // 接受返回主页面时的默认选中
      chartInfo: [], // 传给结果页面的值
      arrData: [], // 存储计算常模的数据
      schedId: "",
      trainId: "",
      imagedata: "",
      imagedatas: require("../../assets/img/headerIcon.png"),
      time: null, // 定时器初始定义
    };
  },

  components: {
    // tabarhead,
    trainVideo,
    trainResult,
  },

  computed: {
    patientPlanId() {
      return this.$route.query.patientPlanId;
    },
    patientId() {
      return this.$store.state.patientInfo.patientId;
    },
    // 按键文本
    btnText() {
      // 如果是从单个训练进入的 在视频页和训练结果页显示完成
      if (this.schedDtlId) {
        if (this.isVideoShow || this.isResultShow) {
          return "完成";
        }
      }
      return "继续";
    },
    handleLongName() {
      if (
        this.patientTrainInfo.patientName &&
        this.patientTrainInfo.patientName.length > 6
      ) {
        return this.patientTrainInfo.patientName.substr(0, 3) + "...";
      }
      return this.patientTrainInfo.patientName;
    },
    isBtnShow() {
      if (this.schedDtlId && this.isVideoShow) {
        return false;
      }
      return true;
    },
  },
  watch: {
    // 在需要的时候监听message事件，否则会不断的触发
    isFrameShow() {
      if (this.isFrameShow === false) {
        console.log("解绑了message事件");
        window.removeEventListener("message", this.handleMessage);
      } else {
        console.log("绑定了message事件");
        window.addEventListener("message", this.handleMessage);
      }
    },
    isVideoShow(value) {
      // 视频页面关闭时 定时器置为null
      if (value === false) {
        clearInterval(this.time);
        this.time = null;
        console.log(this.time);
      }
    },
  },
  created() {
    // this.browserInfo = window.navigator.userAgent
    // console.log('从item进来的', this.$route.query)
    this.patientPlanId2 = this.$route.query.patientPlanId;
    this.schedDtlId = this.$route.query.schedDtlId;
    this.againTrainFlg = this.$route.query.againTrainFlg;
    this.imagedata = this.$route.query.imagedata;
    // 用于判断是否是单个训练 单个的话不显示1/3
    this.canOpenFlag = this.$route.query.canOpenFlag;
    this.currentPlanIndex = this.$route.query.currentPlanIndex;
    // 发送第一次请求
    this.getInitData();
  },

  mounted() {
    this.resetIsShow(); // TODO
    // const element = document.getElementsByTagName("body")[0];
    // let isFull = window.fullScreen || document.webkitIsFullScreen;
    // console.log(isFull);
    // if (isFull === false) {
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen();
    //   } else if (element.webkitRequestFullScreen) {
    //     element.webkitRequestFullScreen();
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen();
    //   } else if (element.msRequestFullscreen) {
    //     // IE11
    //     element.msRequestFullscreen();
    //   }
    // }
    //    window.addEventListener('message', this.handleMessage)
  },

  methods: {
    async handleMessage(event) {
      console.log(event);
      const msg = event.data;
      // 返回的msg是字符串
      // console.log("监听message事件", msg, typeof msg);
      if (msg && msg.length) {
        // this.iframeMsgCount++
        // console.log(this.iframeMsgCount)
        const msgObj = JSON.parse(msg);
        // 0-退出 1-完成
        if (msgObj.code === 2) {
          if (msgObj.data.midway !== 1) {
            this.isFrameShow = false;
            // this.frameSrc = "";
            this.isDetailsShow = true;
            return;
          }
          try {
            const res = await this.$http.get(
              `/train/data/getTrainLocationByNorm?schedId=${this.schedId}&trainId=${this.trainId}&patientId=${this.patientId}`
              // '/train/data/getTrainLocation?schedId=40a1d7f201884ad5ac2963acc9b5316b&trainId=14'
            );
            if (res.code === 1) {
              this.chartInfo = res.data;
              console.log("res location", res);
            } else if (res.code === -2) {
              this.isFrameShow = false;
              // this.frameSrc = "";
              this.isDetailsShow = true;
              return;
            } else {
              this.$Message.error(res.msg);
            }
          } catch (error) {
            console.log(error);
          }
          try {
            const arr = await this.$http.get(
              `/train/data/getTrainNorm?trainId=${this.trainId}&age=${this.patientTrainInfo.patientAge}`
            );
            console.log(arr);
            if (arr.code === 1) {
              this.arrData = arr.data;
              console.log(this.arrData, arr.data);
            }
          } catch (error) {
            console.log(error);
          }
          // 游戏结束 点击确定按钮跳转到结果页
          this.isFrameShow = false;
          this.frameSrc = "";
        }
        if (parseInt(this.patientTrainInfo.todayFinishProcess) !== 100) {
          this.patientTrainInfo.todayFinishProcess =
            parseInt(this.patientTrainInfo.todayFinishProcess) + 10;
        }
        let remains = parseInt(
          this.patientTrainInfo.remainsDuration.split("分")[0]
        );
        let current;
        if (
          isNaN(parseInt(this.patientTrainInfo.currentDuration.split("分")[0]))
        ) {
          current = 0;
        } else {
          current = parseInt(
            this.patientTrainInfo.currentDuration.split("分")[0]
          );
        }
        console.log(remains, current);
        this.patientTrainInfo.remainsDuration = `${remains - current}分钟`;
        console.log(this.patientTrainInfo.remainsDuration);
        this.isResultShow = true;
      }
    },
    // 发送第一次点击【开始训练】的请求
    async getInitData() {
      // 如果当前页面时介绍页，点击【继续】显示iframe页
      if (this.isDetailsShow) {
        this.isDetailsShow = false;
        this.isFrameShow = true;
        // console.log(document.body.scrollHeight, document.body.scrollWidth);
        // console.log(window.screen.height, window.screen.width);
        // let element = this.$refs.iframe;
        // this.$nextTick(() => {
        //   let element =
        //     document.getElementById("iframe").contentWindow.document.body;

        //   if (element.requestFullscreen) {
        //     element.requestFullscreen();
        //   } else if (element.webkitRequestFullScreen) {
        //     element.webkitRequestFullScreen();
        //   } else if (element.mozRequestFullScreen) {
        //     element.mozRequestFullScreen();
        //   } else if (element.msRequestFullscreen) {
        //     // IE11
        //     element.msRequestFullscreen();
        //   }
        // });

        return;
      }
      // 如果当前页面是视频页，判断视频是否已经播放
      //    if (this.isVideoShow && this.playInfo !== 1) {
      //        return this.$Message.error('请先播放视频')
      //    }
      // 每次点击【开始训练/继续】之前 关闭所有的窗口显示
      this.resetIsShow();
      let res;
      try {
        // todo testIdx
        // if (this.testIdx === 3) {
        //     this.testIdx = 1
        //     this.$Message.success('您的今日训练已完成！今日脑力指数：100')
        //     return this.$router.push('/main/brainstatistics')
        // }
        // 判断点击的是否是单个训练
        if (this.schedDtlId) {
          const data = {
            patientId: this.patientId,
            patientPlanId: this.patientPlanId2,
            schedDtlId: this.schedDtlId,
          };
          res = await this.$http.gets(
            "patient/plan/info/getSingleTrainStartTrainInfo",
            this.$http.adornParams(data, false)
          );
        } else {
          // 如果是再练一次添加标识符againTrainFlg
          res = await this.$http.get(
            `patient/plan/info/getStartTrainInfo?patientId=${this.patientId}&patientPlanId=${this.patientPlanId}&againTrainFlg=${this.againTrainFlg}`
          );
        }
        if (res.code === 1) {
          // this.testIdx++
          this.patientTrainInfo = res.data;
          this.schedId = this.patientTrainInfo.schedId;
          this.trainId = this.patientTrainInfo.trainId;
          // console.log('res.data', res.data)
          // todayTrainFinishFlag TODO判断是否显示雷达图页面 0-否 1-是
          // todo  临时变量 res.data.todayTrainFinishFlag
          if (res.data.todayTrainFinishFlag === 1) {
            // this.$Message.success("您的今日训练已完成！今日脑力指数：100");
            return this.$router.push(
              `/main/todaystatistics?schedId=${this.patientTrainInfo.schedId}`
            );
          }
          // 判断训练类型 0-视频 1-训练
          if (res.data.trainFlag === 0) {
            // 标识当前页为视频页
            this.videoUrl = res.data.trainUrl;
            this.isVideoShow = true;
            //  添加 视频播放时接口轮询，防止token过期
            this.time = setInterval(() => {
              this.$http
                .get("api/train/v1/ping")
                .then((res) => {
                  console.log(res);
                  // this.$parent.$parent.$parent.$parent.resetTimer();
                })
                .catch((err) => {
                  console.log(err);
                  clearInterval(this.time);
                  this.time = null;
                });
              console.log("~~~~~");
            }, 60000);
            // this.$router.push("/main/video");
          }
          // 如果是训练的话  先展示训练详情页面
          if (res.data.trainFlag === 1) {
            // 标识当前页为详情页
            //  &orientFlg=1&resultBtnFlg=1  暂时注释掉 由后台返回
            // const element = document.getElementsByTagName("body")[0];
            // let isFull = window.fullScreen || document.webkitIsFullScreen;
            // console.log(isFull);
            // if (isFull === false) {
            //   if (element.requestFullscreen) {
            //     element.requestFullscreen();
            //   } else if (element.webkitRequestFullScreen) {
            //     element.webkitRequestFullScreen();
            //   } else if (element.mozRequestFullScreen) {
            //     element.mozRequestFullScreen();
            //   } else if (element.msRequestFullscreen) {
            //     // IE11
            //     element.msRequestFullscreen();
            //   }
            // }
            res.data.trainUrl = res.data.trainUrl.replace(
              "orientFlg=2",
              "orientFlg=1"
            );
            res.data.trainUrl = res.data.trainUrl.replace(
              "loadOrientFlg=1",
              "loadOrientFlg=2"
            );
            this.frameSrc = res.data.trainUrl;
            console.log(res.data.trainUrl);
            // 获取介绍页的数据
            this.getIntroDetails();
            // this.isDetailsShow = true
            // this.frameSrc = "http://localhost:8081/testpost";
          }
          console.log("patientTrainInfo", this.patientTrainInfo);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取详情页数据的方法
    async getIntroDetails() {
      let res;
      let data = {
        trainId: this.patientTrainInfo.trainId,
      };
      try {
        // 测试testIdx  1是视频 2是训练
        res = await this.$http.post(
          "train/infor/getById",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        );
        if (res.code === 1) {
          this.introInfos = res.data;
          this.isDetailsShow = true;
          console.log("introInfos", this.introInfos);
        } else {
          console.log(res.msg);
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 关闭所有页面组件
    resetIsShow() {
      // TODO
      this.isFrameShow = false;
      this.isResultShow = false;
      this.isDetailsShow = false;
      this.isVideoShow = false;
      this.isRadarShow = false;
    },
    // 当前训练为单个训练时
    clickContinue: Debounce(function () {
      console.log("schedDtlId", this.schedDtlId);
      // 在单个训练时 如果是视频 --- 这个删掉 也不显示下边的按钮
      // if (this.schedDtlId && this.isVideoShow) {
      //     // 1-视频播放 2-播放完成
      //     if (this.playInfo !== 2) {
      //         return this.$Message.error("请先完成训练！");
      //     } else {
      //         this.$Message.success("恭喜你完成训练！");
      //         return this.$router.push("/main");
      //     }
      // }
      // 在单个训练时 如果是训练结果页面
      if (this.schedDtlId && this.isResultShow) {
        // this.$Message.success("恭喜你完成训练！");
        return this.$router.push({
          path: "/main",
          query: {
            currentPlanIndex: this.currentPlanIndex,
          },
        });
      }
      // 正常开始训练时
      this.getInitData();
    }, 1500),
    // handleVideo(val) {
    //   console.log("视频播放信息", val);
    //   // this.playInfo = playInfo
    //   this.playInfo = val;
    // },
    // 返回首页
    backToHome() {
      this.$router.push({
        path: "/main",
        query: {
          currentPlanIndex: this.currentPlanIndex,
        },
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.time = null;
  },
};
</script>
<style lang="scss" scoped>
@import "@css/mixin.scss";
.fullScreen {
  position: fixed !important;
  top: 0 !important;
  width: 1920px !important;
  height: 100% !important;
  margin-top: 0 !important;
}
.container {
  position: relative;
  height: 100%;
  overflow: auto;
  .card {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 1760px;
    height: 920px; // 为了显示底部产品信息
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    line-height: 1;
    padding: 0 24px;
    box-sizing: border-box;
    .card_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #ededed;
      white-space: nowrap;
      div:nth-child(1) {
        display: flex;
        align-items: center;
        padding-right: 60px;
        border-right: 1px solid #ededed;
        img {
          width: 52px;
          height: 52px;
          border-radius: 100%;
        }
        span {
          font-size: 20px;
          font-weight: 400;
          color: #666666;
        }
        span:nth-child(1) {
          font-size: 20px;
          font-weight: bolder;
          color: #333333;
          margin-left: 12px;
        }
        .asv {
          margin: 0 5px;
        }
      }
      div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        p {
          margin-right: 90px;
          white-space: nowrap;
          span {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          span:nth-child(2) {
            margin-left: 12px;
            font-weight: bolder;
            color: #333;
          }
        }
      }
      div:nth-child(3) {
        background: #4174f7;
        border-radius: 6px;
        padding: 10px 14px;
        cursor: pointer;
        p {
          display: flex;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .card_content {
      padding: 40px 56px;
      .video-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        .inner-box {
          width: 100%;
          height: 100%;
          display: flex;
          background: #333;
          border-radius: 5px;
          .video-box {
            width: 1154px;
            height: 649px;
          }
          .video-info {
            width: 446px;
            height: 649px;
            background: #333333;
            border-radius: 12px;
            padding: 60px 40px;
            .title {
              font-size: 24px;
              font-weight: 500;
              color: #ffffff;
            }
            .desc {
              margin-top: 32px;
              font-size: 20px;
              font-weight: 400;
              color: #ffffff;
              line-height: 32px;
              opacity: 0.7;
            }
          }
        }
      }
      .middle {
        width: 100%;
        border: 1px solid #ededed;
        padding: 0 40px;
        .name-times {
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          .zhanwei {
            width: 215px;
          }
          .name-box {
            font-size: 24px;
            font-weight: bolder;
            color: #333333;
            span:nth-child(2) {
              margin-left: 10px;
            }
          }
          .times-box {
            width: 215px;
            .times-box1 {
              min-width: 123px;
              font-size: 20px;
              font-weight: 400;
              color: #666666;
              display: flex;
              white-space: nowrap;
              .word {
                margin-right: 16px;
              }
              .times {
                display: flex;
                .fen {
                  margin: 0 5px;
                }
              }
            }
          }
        }
        .info {
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .title {
            font-size: 20px;
            font-weight: bolder;
            color: #333333;
          }
          .desc {
            margin-top: 24px;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
          }
        }
        .imgs {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 116px;
          .img-box {
            width: 490px;
            height: 276px;
            border-radius: 12px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
        }
      }
      .frame-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        overflow: hidden;
        transform: translateX(-50%);
        // transform: translate(-50%, -50%);
        width: 100%;
        // height: 683px;
        // height: 800px;
        height: 750px;
        // padding-bottom: 30px;
        z-index: 999;
        iframe {
          position: absolute;
          top: 0;
          width: 73%;
          height: 94%;
          /*border-radius: 0 0 40px 40px;*/
        }
      }
      .boxframe {
        position: fixed !important;
        top: 0;
        right: 0 !important;
        left: 0 !important;
        width: 1920px !important;
        height: 100% !important;
        transform: none !important;
        z-index: 10000 !important;
      }
      .bottom {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        .btn-box {
          position: relative;
          width: 200px;
          height: 54px;
          cursor: pointer;
          background: #4174f7;
          box-shadow: 0px 2px 4px 0px rgba(65, 116, 247, 0.3);
          border-radius: 8px;
          .btn-word {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            font-weight: bolder;
            color: #ffffff;
          }
        }
      }
    }
  }
  // .card {
  //   position: absolute;
  //   top: 40px;
  //   left: 80px;
  //   width: 1760px;
  //   // height: 794px;
  //   // height: 900px;
  //   height: 815px; // 为了显示底部产品信息
  //   overflow: hidden;
  //   background: #fff;
  //   border: 1px solid #d2d2d2;
  //   box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
  //   border-radius: 40px;
  //   line-height: 1;
  //   .frame-box {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: absolute;
  //     bottom: 0;
  //     left: 50%;
  //     overflow: hidden;
  //     transform: translateX(-50%);
  //     // transform: translate(-50%, -50%);
  //     width: 100%;
  //     // height: 683px;
  //     // height: 800px;
  //     height: 750px;
  //     // padding-bottom: 30px;
  //     z-index: 999;
  //     border-radius: 0 0 40px 40px;
  //     iframe {
  //       position: absolute;
  //       top: 0;
  //       width: 73%;
  //       height: 94%;
  //       /*border-radius: 0 0 40px 40px;*/
  //     }
  //   }
  //   .boxframe {
  //     position: fixed !important;
  //     top: 0;
  //     right: 0 !important;
  //     left: 0 !important;
  //     width: 1920px !important;
  //     height: 100% !important;
  //     transform: none !important;
  //     z-index: 10000 !important;
  //   }
  //   // 小背景图标
  //   .icon {
  //     // display: inline-block;
  //     width: 24px;
  //     height: 24px;
  //     margin-right: 12px;
  //   }
  //   .name {
  //     font-size: 20px;
  //     color: rgba(102, 102, 102, 1);
  //     font-weight: 400;
  //   }
  //   .val {
  //     font-size: 28px;
  //     color: #000;
  //     font-weight: 500;
  //   }
  //   .head {
  //     display: flex;
  //     flex-direction: row;
  //     // align-items: center;
  //     position: relative;
  //     height: 108px;
  //     padding-left: 60px;
  //     border-radius: 40px 40px 0 0;
  //     background-color: #fff;
  //     border-bottom: 1px solid #d2d2d2;
  //     line-height: unset;
  //     // head中右上角的关闭按钮
  //     .close-btn {
  //       position: absolute;
  //       top: 35px;
  //       right: 53px;
  //       width: 40px;
  //       height: 40px;
  //       background: url("~@/assets/img/patientend/i-close.png") no-repeat;
  //       background-position: center;
  //       background-size: contain;
  //       cursor: pointer;
  //     }
  //     // 解决底部对齐
  //     .info-box {
  //       display: flex;
  //       flex-direction: row;
  //       align-items: flex-end;
  //       margin-top: 37px;
  //     }
  //     .patient {
  //       position: relative;
  //       .icon {
  //         @include setIcon(url("~@/assets/img/patientend/i-user.png"), 20px);
  //       }
  //       padding-right: 25px;
  //       &:after {
  //         content: "";
  //         display: block;
  //         position: absolute;
  //         top: 50%;
  //         right: 0;
  //         transform: translateY(-50%);
  //         width: 1px;
  //         height: 18px;
  //         background: #d2d2d2;
  //       }
  //       //  border-right: 1px solid blue;
  //       .gender,
  //       .age {
  //         margin-left: 20px;
  //         font-size: 20px;
  //         color: #000;
  //         font-weight: 500;
  //       }
  //       /deep/ .ivu-poptip-content {
  //         font-size: 24px;
  //         font-weight: 500;
  //       }
  //       .val {
  //         line-height: 24px;
  //       }
  //     }
  //     .current-train {
  //       position: relative;
  //       padding: 0 25px;
  //       //  border-right: 1px solid blue;
  //       .icon {
  //         // background: url("~@/assets/img/patientend/i-data.png");
  //         // background-position: center;
  //         // background-size: cover;
  //         @include setIcon(url("~@/assets/img/patientend/i-train.png"), 24px);
  //       }
  //       .val {
  //         line-height: 24px;
  //       }
  //       &:after {
  //         content: "";
  //         display: block;
  //         position: absolute;
  //         top: 50%;
  //         right: 0;
  //         transform: translateY(-50%);
  //         width: 1px;
  //         height: 18px;
  //         background: #d2d2d2;
  //       }
  //     }
  //     .current-duration {
  //       position: relative;
  //       padding: 0 25px;
  //       .icon {
  //         // background: url("~@/assets/img/patientend/i-clock.png");
  //         // background-position: center;
  //         // background-size: cover;
  //         @include setIcon(
  //           url("~@/assets/img/patientend/i-duration.png"),
  //           24px
  //         );
  //       }
  //       .val {
  //         line-height: 24px;
  //       }
  //       &:after {
  //         content: "";
  //         display: block;
  //         position: absolute;
  //         top: 50%;
  //         right: 0;
  //         transform: translateY(-50%);
  //         width: 1px;
  //         height: 18px;
  //         background: #d2d2d2;
  //       }
  //     }
  //     .remain-time {
  //       position: relative;
  //       padding: 0 25px;
  //       .icon {
  //         // background: url("~@/assets/img/patientend/i-clock.png");
  //         // background-position: center;
  //         // background-size: cover;
  //         @include setIcon(url("~@/assets/img/patientend/i-remain.png"), 24px);
  //       }
  //       .val {
  //         line-height: 24px;
  //       }
  //       &:after {
  //         content: "";
  //         display: block;
  //         position: absolute;
  //         top: 50%;
  //         right: 0;
  //         transform: translateY(-50%);
  //         width: 1px;
  //         height: 18px;
  //         background: #d2d2d2;
  //       }
  //     }
  //     .today-finished {
  //       position: relative;
  //       padding: 0 25px;
  //       .percent {
  //         margin-left: 7px;
  //         font-size: 20px;
  //         font-weight: 400;
  //         color: #000000;
  //       }
  //       .val {
  //         line-height: 24px;
  //       }
  //       .icon {
  //         // background: url("~@/assets/img/patientend/i-clock.png");
  //         // background-position: center;
  //         // background-size: cover;
  //         @include setIcon(
  //           url("~@/assets/img/patientend/i-finished.png"),
  //           24px
  //         );
  //       }
  //     }
  //   }
  //   .middle {
  //     display: flex;
  //     flex-direction: column;
  //     padding: 40px 160px 0;
  //     .name-times {
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: center;
  //       // align-items: flex-end;
  //       height: 25px;
  //       .name-box {
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: center;
  //         align-items: flex-end;
  //         height: 24px;
  //         .icon1 {
  //           width: 24px;
  //           height: 24px;
  //           background: url("~@/assets/img/patientend/traininfo-name.png")
  //             no-repeat;
  //           background-size: contain;
  //         }
  //         .name {
  //           margin-left: 15px;
  //           font-size: 20px;
  //           font-weight: 400;
  //           color: #666666;
  //         }
  //       }
  //       .line {
  //         width: 1px;
  //         height: 24px;
  //         margin: 0 30px;
  //         background: #d2d2d2;
  //       }
  //       .times-box {
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: center;
  //         align-items: flex-end;
  //         .word {
  //           font-size: 20px;
  //           font-weight: 400;
  //           color: #666666;
  //         }
  //         .times {
  //           display: flex;
  //           flex-direction: row;
  //           align-items: center;
  //           margin-left: 13px;
  //           .finish-num {
  //             font-size: 28px;
  //             font-weight: 500;
  //             color: #4174F7;
  //           }
  //           .icon2 {
  //             width: 11px;
  //             height: 24px;
  //             margin-left: 6px;
  //             background: url("~@/assets/img/patientend/traininfo-num.png")
  //               no-repeat;
  //             background-size: contain;
  //             background-position: center center;
  //           }
  //           .remain-num {
  //             margin-left: 6px;
  //             font-size: 28px;
  //             font-weight: 500;
  //             color: #999999;
  //           }
  //         }
  //       }
  //     }
  //     .info {
  //       display: flex;
  //       flex-direction: column;
  //       // align-items: center;
  //       margin-top: 40px;
  //       margin-bottom: 40px;
  //       .title {
  //         margin-bottom: 19px;
  //         font-size: 24px;
  //         font-weight: 500;
  //         color: #000000;
  //       }
  //       .desc {
  //         // width: 50%;
  //         font-size: 20px;
  //         font-weight: 400;
  //         line-height: 40px;
  //         color: #010101;
  //         opacity: 0.7;
  //       }
  //     }
  //     .imgs {
  //       display: flex;
  //       flex-direction: row;
  //       .img-box {
  //         width: 472px;
  //         height: 264px;
  //         background: #ccc;
  //         &:nth-child(2) {
  //           margin: 0 12px;
  //         }
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .video-wrapper {
  //     height: 450px;
  //     background: url("~@/assets/img/patientend/video-background.png") no-repeat;
  //     background-position: center;
  //     background-size: contain;
  //     .inner-box {
  //       display: flex;
  //       justify-content: center;
  //       width: 1100px;
  //       height: 100%;
  //       margin-left: 331px;
  //       .video-box {
  //         width: 800px;
  //         height: 100%;
  //       }
  //       .video-info {
  //         width: 300px;
  //         height: 100%;
  //         padding: 0 30px;
  //         background: #676e75;
  //         .title {
  //           margin-top: 90px;
  //           font-size: 24px;
  //           font-weight: 500;
  //           color: #ffffff;
  //         }
  //         .desc {
  //           margin-top: 17px;
  //           font-size: 20px;
  //           font-weight: 400;
  //           color: #ffffff;
  //           line-height: 32px;
  //           opacity: 0.7;
  //         }
  //       }
  //     }
  //   }
  //   .bottom {
  //     // display: flex;
  //     // flex-direction: column;
  //     // align-items: center;
  //     position: absolute;
  //     bottom: 20px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     .btn-box {
  //       position: relative;
  //       width: 314px;
  //       height: 90px;
  //       cursor: pointer;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //       .btn-word {
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -60%);
  //         font-size: 36px;
  //         font-weight: 500;
  //         color: #ffffff;
  //       }
  //     }
  //   }
  // }
}
</style>
